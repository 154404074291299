import { Component, OnInit, Input, Output, EventEmitter, ViewChild  } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { FinanceService } from '@intranet/modules/finance/finance.service';

import { ClientsService } from '../clients.service';
import { LoginService } from '@intranet/modules/login/login.service';
import { Currency } from '@intranet/modules/finance/finance.types';
import { Client, ClientAddress } from '../client.types';
import { ClientGroup } from '..';
import { HotkeyService } from 'src/app/core/services/hotkey.service';

@Component({
    selector: 'client-add',
    templateUrl: './client-add.component.html',
})
export class ClientAddComponent implements OnInit {
    @Input() showDialog;
    @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() update: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('clientForm') clientForm: NgForm;

    isEditing: boolean = false;
    error: any;
    client: Client;
    address: ClientAddress;
    state: boolean;
    title: string;
    archiveClient: boolean;
    currencies: Currency[] = [];
    groups: ClientGroup[];
    filteredGroups: ClientGroup[];

    constructor(
        private clientService: ClientsService,
        private route: ActivatedRoute,
        private router: Router,
        private financeService: FinanceService,
        private hotkeyService: HotkeyService,
        public loginService: LoginService
    ) {}

    ngOnInit(): void {
        // Register hotkey for "Cancel" action (Ctrl + Q)
        this.hotkeyService.registerHotkey('ctrl+q', 'Cancel action', () => this.onCancel());

        // Register hotkey for "Save" action (Ctrl + Alt + S)
        this.hotkeyService.registerHotkey(
          'ctrl+alt+s',
          'Save client',
          () => {
              if (!this.isSaveDisabled()) {
                  this.onSubmit();
              }
          }
      );

        combineLatest([
            this.financeService.getCurrencies(),
            this.clientService.getClientGroups(),
            this.route.paramMap,
        ])
            .pipe(
                switchMap(([currencies, groups, params]) => {
                    let clientIdParam = params.get('clientid');
                    let clientId;

                    if (clientIdParam !== null) {
                        this.isEditing = true;
                        clientId = +clientIdParam;
                    }

                    return this.clientService
                        .getOrInitClient(clientId)
                        .pipe(
                            map((client) => ({ currencies, groups, client }))
                        );
                }),
                tap(({ currencies, groups, client }) => {
                    // Update local refs
                    this.currencies = currencies;
                    this.client = client;

                    // Populate client groups
                    this.groups = groups;
                    this.filteredGroups = groups;

                    if (client.addresses.length) {
                        this.address = client.addresses[0];
                        this.address.description = 'Head Office';
                    }
                    // Default terms
                    if (!client.quoteTerms1) {
                        this.client.quoteTerms1 =
                            'Valid for 30 days from date of estimate';
                    }
                    if (!client.quoteTerms2) {
                        this.client.quoteTerms2 =
                            'Amends to the brief may incur additional charges';
                    }
                    if (!client.invoicePaymentTerms) {
                        this.client.invoicePaymentTerms =
                            'Payment terms: 30 days from date of invoice';
                    }

                    if (this.isEditing) {
                        this.title = 'Edit client';
                    } else {
                        this.title = 'Add new client';
                    }

                    // Default to GBP (if exists), or first
                    if (!this.client.currencyId) {
                        let gbp = this.currencies.find(
                            (c) => c.code.toLowerCase() === 'gbp'
                        );
                        this.client.currencyId = gbp
                            ? gbp.currencyId
                            : this.currencies[0].currencyId;
                    }
                })
            )
            .subscribe({
                error: (error) => {
                    console.log(error)
                    this.error = error;
                },
            });
    }

    onGroupChange(value) {
        this.filteredGroups = this._filterGroups(value);
    }

    onSubmit() {
        if (this.client.groupName?.length) {
            this.client.groupId = this.groups.find(
                (g) => g.name === this.client.groupName
            )?.clientGroupId;
        } else {
            this.client.groupId = null;
        }

        if (this.isEditing) {
            this.clientService.updateClient(this.client).subscribe({
                complete: () => this.closeDialog(),
            });
        } else {
            this.clientService.addClient(this.client).subscribe({
                next: (client) => {
                    this.update.emit();
                    this.router.navigate([
                        `/clients/dashboard/${client.clientId}/detail`,
                    ]);
                },
                complete: () => this.closeDialog(),
                error: (error) => (this.error = error),
            });
        }
    }

    onCancel() {
        this.closeDialog();
    }

    closeDialog() {
        // Retrieves showDialog input, turns to false and returns back to parent in order to close modal
        this.showDialog = false;
        this.close.emit(this.showDialog);
    }

    private isSaveDisabled(): boolean {
        return !this.clientForm.valid || this.clientForm.pristine;
    }

    private _filterGroups(value: string): ClientGroup[] {
        const filterValue = value.toLowerCase();

        return this.groups.filter((state) =>
            state.name.toLowerCase().includes(filterValue)
        );
    }
}
