import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { LoginService } from '@intranet/modules/login/login.service';
@Component({
    selector: 'header-component',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    constructor(
        public loginService: LoginService,
        public router: Router,
    ) {}

    displayName: string;

    ngOnInit(): void {
        this.displayName = this.getUser();
    }

    getUser() {
        let username = this.loginService.getLoggedInAdUserDisplayName();

        return username.substr(0, username.indexOf(' '));
    }

    logout() {
        this.loginService.logoutAzure();
    }
}
