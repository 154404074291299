import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap, take, tap, map } from 'rxjs/operators';
import { JobService } from '../job.service';
import { LoginService } from '@intranet/modules/login/login.service';
import { UserService } from '@intranet/modules/users/user.service';
import { User, UserOrGroup } from '@intranet/modules/users/user.types';
import { JobAction } from '@intranet/modules/actions/action.types';
import { Job, JobStatus, JobType, StatusDetail } from '../job.types';
import { combineLatest, Observable, of } from 'rxjs';
import { Client, ClientsService } from '@intranet/modules/clients';
import { orderClientsByGroupName } from '@intranet/modules/clients/client.helpers';
import { HotkeyService } from 'src/app/core/services/hotkey.service';

@Component({
    selector: 'jobs-manage',
    templateUrl: './jobs-manage.component.html',
    styleUrls: ['./jobs-manage.component.scss'],
})
export class JobsManageComponent implements OnInit, AfterViewInit {
    @Input() clients: Client[] = [];
    @Input() jobId: number | null;
    @Input() showDialog: boolean;
    @Output() changed = new EventEmitter();
    @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('jobTitleField') jobTitleField: ElementRef;
    @ViewChild('jobForm') jobForm: NgForm;

    submitting = false;
    job: Job;
    jobTypes: JobType[];
    users: User[];
    filterClients: [Client[], Client[]] = [[], []];
    allClients: Client[] = [];
    isGroup: boolean = false;
    defaultApproval: JobAction | undefined;
    defaultInvoice: JobAction | undefined;
    disableClientSelection = false;
    _jobOwner: UserOrGroup;
    allowCompleted: boolean = true;
    error: string;
    title: string;
    jobStatuses: StatusDetail[] = [
        new StatusDetail(JobStatus.Live, 'Live'),
        new StatusDetail(JobStatus.Completed, 'Completed'),
    ];
    saveIntent: 'close' | 'open' | null = null;

    get jobOwner(): UserOrGroup {
        return this._jobOwner;
    }
    set jobOwner(uog: UserOrGroup) {
        this._jobOwner = uog;
        if (uog.isUser) {
            this.job.jobOwner = uog.user;
        }
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private jobService: JobService,
        private loginService: LoginService,
        private userService: UserService,
        private clientService: ClientsService,
        private hotkeyService: HotkeyService
    ) {}

    ngOnInit(): void {
        // Register hotkey for "Cancel" action (Ctrl + Q)
        this.hotkeyService.registerHotkey('ctrl+q', 'Cancel action', () => this.onCancel());

        // Register hotkey for "Save" action (Ctrl + Alt + S)
        this.hotkeyService.registerHotkey('ctrl+alt+s', 'Save action', () => {
            if (
                this.showDialog &&
                !this.submitting &&
                this.saveIntent === null &&
                this.jobForm?.valid &&
                !this.jobForm?.pristine
              ) {
                if (!this.job?.jobId) {
                    // Add job modal
                    this.onSaveIntent('close');
                    this.onSubmit();
                } else {
                    // Edit job modal
                    this.onSubmit();
                }
            }
        });

        // Register hotkey for "Save and Open" action (Ctrl + O)
        this.hotkeyService.registerHotkey('ctrl+o', 'Save and Open action', () => {
            if (
                this.showDialog &&
                !this.submitting &&
                this.saveIntent === null &&
                this.jobForm?.valid &&
                !this.jobForm?.pristine &&
                !this.job?.jobId // Only for "Add job" modal
            ) {
                this.onSaveIntent('open');
                this.onSubmit();
            }
        });

        let observables$: Observable<any>[] = [];
        observables$.push(this.route.paramMap);
        observables$.push(this.jobService.jobTypes$);
        observables$.push(this.userService.users$);

        // If we are not explicitly passed in clients, we need to populate
        if (!this.clients.length) {
            observables$.push(this.clientService.clients$);
            observables$.push(this.clientService.myClients$);
        } else {
            observables$.push(of(this.clients));
        }

        combineLatest(observables$)
            .pipe(
                take(1),
                tap(([paramMap, jobTypes, users, allClients, myClients]) => {
                    if (allClients) {
                        this.allClients = allClients;

                        if (allClients.length > 1) {
                            const [pinnedClients, restClients] =
                                orderClientsByGroupName(
                                    allClients,
                                    myClients?.map((c) => c.clientId) ?? [],
                                    true
                                );

                            this.filterClients = [
                                pinnedClients as Client[],
                                restClients as Client[],
                            ];
                        } else {
                            this.filterClients = [[], this.clients];
                        }
                    }

                    this.jobTypes = jobTypes;
                    this.users = users.filter((user) => user.active);
                }),
                map(([paramMap]) => paramMap as ParamMap),
                switchMap((p) => {
                    let id = p.get('id');
                    if (id) {
                        return this.jobService.getJob(+id);
                    } else {
                        // Modal alternative
                        if (this.jobId) {
                            return this.jobService.getJob(this.jobId);
                        }
                    }
                    return of(<Job>{
                        jobStatus: JobStatus.Live,
                        jobType: {},
                        active: true,
                        jobOwnerId: this.loginService.userId,
                    });
                })
            )
            .subscribe({
                next: (job) => {
                    if (!job.archive && !job.canArchive) {
                        this.allowCompleted = false;
                    }
                    this.job = job;
                    this.job.jobTypeId = this.job.jobType.jobTypeId;

                    if (this.jobService.filterQuery?.clientId) {
                        this.filterClients = [
                            [],
                            [
                                this.allClients.find(
                                    (c) =>
                                        c.clientId ===
                                        this.jobService.filterQuery.clientId
                                ),
                            ],
                        ];
                    }

                    if (this.filterClients[1].length === 1) {
                        this.job.clientId =
                            this.jobService.filterQuery?.clientId ||
                            this.clients[0].clientId;
                        this.disableClientSelection = true;
                    }

                    if (this.job && this.job.jobId) {
                        this.title = 'Edit job';
                    }
                    if (!this.job.jobId) {
                        this.title = 'Add job';
                        this.job.jobOwner = this.userService.users.find(
                            (u) => u.id === this.loginService.userId
                        );
                    }
                },
                error: (error) => {
                    this.error = error;
                },
            });
    }

    ngAfterViewInit(): void {
        this.jobTitleField.nativeElement?.focus();
    }

    onSaveIntent(saveIntent: 'close' | 'open') {
        this.saveIntent = saveIntent;
    }

    onSubmit() {
        // Return if already submitting
        if (this.submitting) return;

        this.submitting = true;

        if (!this.job.jobId) {
            this.job.jobActions = [];
            if (this.defaultApproval) {
                this.job.jobActions.push(this.defaultApproval);
            }
            if (this.defaultInvoice) {
                this.job.jobActions.push(this.defaultInvoice);
            }
        }

        this.jobService.addOrUpdateJob(this.job).subscribe({
            next: (result) => {
                if (!result.success) {
                    this.error = result.error;
                } else {
                    if (!this.jobId && this.saveIntent === 'open') {
                        this.router.navigateByUrl(
                            `/jobs/detail/${result.job.jobId}?triggerAddAction=true`
                        );
                    } else if (this.showDialog) {
                        this.onChanged();
                    } else {
                        this.router.navigate([
                            `/clients/dashboard/${result.job.clientId}`,
                        ]);
                    }
                }
            },
            error: (error) => {
                this.error = error;
            },
            complete: () => {
                this.submitting = false;
            },
        });
    }

    onChanged() {
        // Retrieves showDialog input, turns to false and returns back to parent in order to close modal
        this.showDialog = false;
        this.changed.emit(this.showDialog);
    }

    onCancel() {
        // Retrieves showDialog input, turns to false and returns back to parent in order to close modal
        this.showDialog = false;
        this.close.emit(this.showDialog);
    }

    sortJobTypes(prop: string) {
        const sorted = this.jobTypes.sort((a, b) =>
            a[prop] > b[prop] ? 1 : a[prop] === b[prop] ? 0 : -1
        );
        // asc/desc
        if (prop.charAt(0) === '-') {
            sorted.reverse();
        }
        return sorted;
    }
}
