import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { InfoComponent } from './info.component';
import { infoRoutes } from './info.routes';

@NgModule({
    declarations: [InfoComponent],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule.forChild(infoRoutes), // Load the route for this module
    ],
})
export class InfoModule {}
