import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HotkeyService } from 'src/app/core/services/hotkey.service';

import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import * as moment from 'moment';
import { ActionService } from '../action.service';
import { LoginService } from '@intranet/modules/login/login.service';
import { UserService } from '@intranet/modules/users/user.service';
import {
    User,
    UserGroup,
    UserOrGroup,
} from '@intranet/modules/users/user.types';
import {
    ActionNote,
    JobAction,
    JobActionType,
} from '@intranet/modules/actions';
import {
    ActionJiraLink,
    ActionRecurrence,
    ActionStatus,
    RecurrenceType,
} from '../action.types';
import {
    daysOfWeek,
    recurranceIntervals,
    statuses,
    priorities,
} from '../action.utils';
import { NgForm, ValidatorFn } from '@angular/forms';
import { RadioPickerItem } from '@intranet/shared/radiopicker/radiopicker.component';
import Quill from 'quill';

@Component({
    selector: 'action-manage',
    templateUrl: './action-manage.component.html',
    styleUrls: ['./action-manage.component.scss'],
})
export class ActionManageComponent implements OnInit, AfterViewInit {
    @ViewChild(NgForm) form: NgForm;
    @ViewChild('waitingUserControl', { static: false })
    waitingUserControlRef: ElementRef;
    @ViewChild('titleField') titleField: ElementRef;

    @Input() jobActionId: number;
    @Input() jobId: number;
    @Input() nextActionStatus: ActionStatus | null;
    @Input() propertyKey: string;
    @Input() readonly = false;

    @Output() changed = new EventEmitter<{
        action: JobAction;
        type: 'created' | 'create-multi' | 'updated' | 'deleted';
    }>();
    @Output() cancel = new EventEmitter();

    submitting = false;
    loggedInUserId: string;
    error: string;
    action: JobAction;
    recurrence: ActionRecurrence | undefined | null;
    isNewRecurringAction = false;
    users: User[];
    usersInGroups: UserOrGroup[];
    userGroups: UserGroup[];
    assignees: UserOrGroup[];
    assignToGroup = false;
    monthNumbers: number[] = [];
    maxDayNumbers: number[] = [];
    dayNumbers: number[] = [];
    userOrGroupChanged: boolean = false;
    waitingOnUserOrGroupId: string | number = null;
    _recurrenceType = 'none';
    _userOrGroup = null;

    showEditJiraLinks = false;

    protected modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
        ],
    };

    protected userIds: string[] = [];
    protected groupIds: number[] = [];
    protected waitingUserIds: string[] = [];
    protected waitingGroupIds: number[] = [];
    protected userSelectValid = true;
    protected addAnother = false;

    private validateScheduleDate: ValidatorFn;
    private validateDeadlineDate: ValidatorFn;

    constructor(
        private loginService: LoginService,
        private actionService: ActionService,
        private userService: UserService,
        private route: ActivatedRoute,
        private hotkeyService: HotkeyService,
        private _changeDetector: ChangeDetectorRef
    ) {
        this.loggedInUserId = this.loginService.userId;

        for (var i = 1; i < 32; i++) {
            this.maxDayNumbers.push(i);
        }

        const dateValidator = (key: string) => {
            const clearTime = (date: Date) => {
                date.setHours(0, 0, 0, 0);
                return date;
            };

            return () => {
                let scheduleDate = this.form.form.get('scheduleDate')?.value;
                let deadlineDate = this.form.form.get('deadlineDate')?.value;

                if (scheduleDate && deadlineDate) {
                    scheduleDate = clearTime(new Date(scheduleDate));
                    deadlineDate = clearTime(new Date(deadlineDate));

                    if (deadlineDate < scheduleDate) {
                        return { [key]: 'error' };
                    }
                }

                return null;
            };
        };

        this.validateScheduleDate = dateValidator('scheduleDate');
        this.validateDeadlineDate = dateValidator('deadlineDate');

        this.dayNumbers = this.maxDayNumbers;
    }

    ngAfterViewInit(): void {
        if (this.titleField?.nativeElement) {
            this.titleField.nativeElement.focus();
        }
    }

    ngOnInit(): void {
        // Register hotkey for "Cancel" action (Ctrl + Q)
        this.hotkeyService.registerHotkey('ctrl+q', 'Cancel action', () => this.onCancel());

        // Register hotkey for "Save" action (Ctrl + Alt + S)
        this.hotkeyService.registerHotkey('ctrl+alt+s', 'Save action', () => {
            if (
                !this.submitting &&
                this.form?.valid &&
                !this.form?.pristine &&
                this.showEditJiraLinks === false
            ) {
                this.onSubmit(); // Trigger the Save button
            }
        });

        // Register hotkey for "Save and Add Another" action (Ctrl + O)
        this.hotkeyService.registerHotkey('ctrl+o', 'Save and Add Another action', () => {
            if (
                !this.submitting &&
                this.form?.valid &&
                !this.form?.pristine &&
                !this.action.jobActionId
            ) {
                this.addAnother = true; // Set the flag for saving and adding another
                this.onSubmit(); // Trigger the Save and Add Another button
            }
        });

        this.route.paramMap
            .pipe(
                switchMap((p) => {
                    // Get users and groups
                    this.users = this.userService.users.filter(
                        (user) => user.active
                    );
                    this.userGroups = this.userService.groups;

                    // Look in params for jobId
                    let jobActionId = p.get('jobActionId');
                    if (jobActionId) {
                        this.jobActionId = parseInt(jobActionId);
                    } else {
                        // Check if jobActionId is passed in via props
                        jobActionId =
                            this.route.snapshot.data?.jobActionId ||
                            this.jobActionId;
                    }

                    if (this.jobActionId) {
                        return this.actionService.get(this.jobActionId);
                    } else {
                        let jobId =
                            this.route.snapshot.data?.jobId || this.jobId;

                        return of({
                            success: true,
                            error: null,
                            jobAction: <JobAction>{
                                jobId,
                                isDeleted: false,
                                jobActionType: JobActionType.Standard,
                                actionStatus: ActionStatus.NoAction,
                                scheduleDate: new Date(),
                                assignedUsers: [this.loggedInUserId],
                                assignedGroups: [],
                                jiraLinks: [],
                                priority: 0,
                                latestNote: {
                                    text: ""
                                }
                            },
                        });
                    }
                })
            )
            .subscribe({
                next: (r) => {
                    if (r.success) {
                        this.action = r.jobAction;
                        if (r.jobAction) {
                            this.recurrence = this.action.actionRecurrence;

                            if (this.recurrence) {
                                switch (this.recurrence.recurrenceType) {
                                    case RecurrenceType.Daily:
                                        this._recurrenceType = 'daily';
                                        break;
                                    case RecurrenceType.Weekly:
                                        this._recurrenceType = 'weekly';
                                        break;
                                    case RecurrenceType.Monthly:
                                        this._recurrenceType = 'monthly';
                                        break;
                                    case RecurrenceType.Yearly:
                                        this._recurrenceType = 'yearly';
                                        break;
                                    default:
                                }
                            }

                            this.waitingOnUserOrGroupId =
                                this.action.waitingUserId ??
                                this.action.waitingGroupId ??
                                null;
                            this.waitingUserIds = this.action.waitingUserId ? [this.action.waitingUserId] : [];
                            this.waitingGroupIds = this.action.waitingGroupId ? [this.action.waitingGroupId] : [];
                            this.userIds = this.action?.assignedUsers ?? [
                                this.loggedInUserId,
                            ];
                            this.groupIds = this.action?.assignedGroups ?? [];
                            this.userSelectValid =
                                this.userIds.length > 0 ||
                                this.groupIds.length > 0;

                            this.action.actionStatus =
                                this.nextActionStatus ||
                                this.action.actionStatus;
                            if (this.action.jobActionId && this.action.jiraLinks?.length == 0 && this.propertyKey === 'jira') {
                                this.action.jiraLinks = [{ 
                                    url: '', 
                                    title: '', 
                                    jobActionId: this.action.jobActionId,
                                    jiraLinkId: 0,
                                    board: '', 
                                    issue: '', 
                                    isIssue: false }];
                                this.showEditJiraLinks = true;
                            }
                        }
                    } else {
                        this.error = r.error;
                    }
                },
                error: (error) => {
                    this.error = error;
                },
            });
    }

    get recurrenceType(): string {
        return this._recurrenceType;
    }

    get userOrGroup(): UserOrGroup {
        return this._userOrGroup;
    }

    get statuses() {
        if (!this.jobActionId) {
            return statuses.filter(
                (s) =>
                    s.value !== ActionStatus.Completed &&
                    s.value !== ActionStatus.Archived
            );
        }

        return statuses;
    }

    get priorities() {
        return priorities;
    }

    get recurranceIntervals() {
        return recurranceIntervals;
    }

    set recurrenceType(value: string) {
        this._recurrenceType = value;
        if (!this.recurrence && this._recurrenceType !== 'none') {
            var recurrence = new ActionRecurrence();
            if (this.action.scheduleDate) {
                var deadline = moment(this.action.scheduleDate);
                recurrence.dayOfMonth = deadline.get('date');
                recurrence.monthOfYear = deadline.get('month') + 1;
                switch (deadline.weekday()) {
                    case 0:
                        recurrence.sunday = true;
                        break;
                    case 1:
                        recurrence.monday = true;
                        break;
                    case 2:
                        recurrence.tuesday = true;
                        break;
                    case 3:
                        recurrence.wednesday = true;
                        break;
                    case 4:
                        recurrence.thursday = true;
                        break;
                    case 5:
                        recurrence.friday = true;
                        break;
                    case 6:
                        recurrence.saturday = true;
                        break;
                    default:
                }
            } else {
                recurrence.dayOfMonth = 1;
                recurrence.monthOfYear = 1;
            }
            recurrence.everyNWeeks = 1;
            this.recurrence = recurrence;
        } else if (this._recurrenceType === 'none') {
            this.recurrence = null;
        }
        if (this.recurrence) {
            switch (this._recurrenceType) {
                case 'daily':
                    this.recurrence.recurrenceType = RecurrenceType.Daily;
                    break;
                case 'weekly':
                    this.recurrence.recurrenceType = RecurrenceType.Weekly;
                    break;
                case 'monthly':
                    this.recurrence.recurrenceType = RecurrenceType.Monthly;
                    break;
                case 'yearly':
                    this.recurrence.recurrenceType = RecurrenceType.Yearly;
                    break;
                default:
            }
        }
    }

    onUserOrGroupSelected({
        userIds,
        groupIds,
    }: {
        userIds: string[];
        groupIds: number[];
    }) {
        this.action.assignedUsers = userIds;
        this.action.assignedGroups = groupIds;
        this.userSelectValid = userIds.length > 0 || groupIds.length > 0;
        this.form.form.markAsDirty();
    }

    onScheduleDateChange() {
        if (!this.action.scheduleDate) {
            this.action.deadlineDate = null;
        }
    }

    onClearDate(prop: string) {
        this.action[prop] = null;
    }

    isControlValid(modelValue) {
        return !!modelValue;
    }

    handleSubmit(isRecurring: boolean) {
        // Return if already submitting
        if (this.submitting) return;
        this.submitting = true;
        this._assignWaitingUserOrGroup();

        if (!this.action.jobActionId) {
            if (this._recurrenceType !== 'none') {
                this.action.actionRecurrence = this.recurrence;
            }
            this.actionService.create(this.action).subscribe({
                next: (response) =>
                    this.changed.emit({
                        action: response,
                        type: this.addAnother ? 'create-multi' : 'created',
                    }),
                error: (error) => (this.error = error),
                complete: () => (this.submitting = false),
            });
        } else {
            if (this._recurrenceType === 'none') {
                this.action.actionRecurrence = null;
            } else {
                this.action.actionRecurrence = this.recurrence;
            }
            this.actionService.update(this.action).subscribe({
                next: (response) =>
                    this.changed.emit({ action: response, type: 'updated' }),
                error: (error) => (this.error = error),
                complete: () => (this.submitting = false),
            });
        }
    }

    handleWeekday(day: string) {
        if (day && this.recurrence) {
            daysOfWeek
                .filter((d) => d !== day)
                .forEach((day) => {
                    this.recurrence[day] = false;
                });
        }
    }

    adjustDayNumbers() {
        if (this.recurrence) {
            switch (this.recurrence.monthOfYear) {
                case 1:
                case 3:
                case 5:
                case 7:
                case 8:
                case 10:
                case 12: {
                    this.dayNumbers = this.maxDayNumbers;
                    break;
                }
                case 2: {
                    this.dayNumbers = this.maxDayNumbers.slice(0, 28);
                    if (this.recurrence.dayOfMonth > 28) {
                        this.recurrence.dayOfMonth = 28;
                    }
                    break;
                }
                case 4: {
                }
                case 6: {
                }
                case 9: {
                }
                case 11: {
                    this.dayNumbers = this.maxDayNumbers.slice(0, 30);
                    if (this.recurrence.dayOfMonth > 30) {
                        this.recurrence.dayOfMonth = 30;
                    }
                    break;
                }
                default:
            }
        }
    }

    onSubmit() {
        this.handleSubmit(true);
    }

    onCancel() {
        this.cancel.emit();
    }

    onNotesChanged({
        notes,
        type,
    }: {
        notes: ActionNote[];
        type: 'created' | 'updated' | 'deleted';
    }) {
        this.action.notes = notes;
        this.changed.emit({ action: this.action, type });
    }

    onStatusChange(e: RadioPickerItem) {
        if (e.value.toString() !== ActionStatus.Waiting.toString() && this.propertyKey === 'actionStatus'){
            this.handleSubmit(true);
            return;
        }
        if (e.value.toString() === ActionStatus.Waiting.toString()) {
            this._changeDetector.detectChanges();
            this.waitingUserControlRef?.nativeElement.focus();
        }
    }

    handleWaitingUserSelected(userOrGroup: UserOrGroup) {
        if (userOrGroup.isGroup) {
            this.action.actionStatus = ActionStatus.Waiting;
            this.waitingOnUserOrGroupId = userOrGroup.group.userGroupId;
            this.handleSubmit(true);
        } else if (userOrGroup.isUser) {
            this.action.actionStatus = ActionStatus.Waiting;
            this.waitingOnUserOrGroupId = userOrGroup.user.id;
            this.handleSubmit(true);
        }
    }

    onPriorityChange(e: RadioPickerItem) {
        if (this.propertyKey === 'priority') {
            this.handleSubmit(true);
        }
    }

    private _assignWaitingUserOrGroup() {
        if (this.waitingOnUserOrGroupId) {
            if (typeof this.waitingOnUserOrGroupId === 'string') {
                this.action.waitingUserId = this.waitingOnUserOrGroupId;
                this.action.waitingGroupId = null;
            } else if (typeof this.waitingOnUserOrGroupId === 'number') {
                this.action.waitingGroupId = this.waitingOnUserOrGroupId;
                this.action.waitingUserId = null;
            }
        }
    }

    onEditorCreated(editor: Quill) {
        // Remove tabbing in the editor
        //editor.setSelection(editor.getLength(), editor.getLength());
        delete editor.keyboard['bindings']['9'];
    }

    addJiraLink() {
        this.action.jiraLinks.push(
            {
                url: '',
                title: '',
                jobActionId: this.action.jobActionId,
                jiraLinkId: 0,
                board: '',
                issue: '',
                isIssue: false
            });
        
    }

    removeJiraLink(index: number) {
        this.action.jiraLinks.splice(index, 1);
        // mark the form as dirty
        this.form.form.markAsDirty();
    }

    onLeaveJiraLink(index: number) {
        const link = this.action.jiraLinks[index];
        const url = link.url;
        const title = link.title;
        if (!url || title) {
            return;
        }
        link.title = '';
        this.actionService.getJiraName(url).subscribe((result) => {
            if (result.success) {
                link.title = result.jiraName;
                this.form.form.markAsDirty();
            } else {
                //link.title = 'Unable to find Jira name';
            }
        });
    }

}
