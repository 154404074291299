import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Finance } from '../../finance.types';
import { HotkeyService } from 'src/app/core/services/hotkey.service';

export interface DialogData {
    finance: Finance;
}

@Component({
    selector: 'finance-approve-dialog',
    templateUrl: 'finance-approve-dialog.component.html',
    styleUrls: ['finance-approve-dialog.component.scss'],
})
export class FinanceApproveDialog {
    constructor(
        public dialogRef: MatDialogRef<FinanceApproveDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private hotkeyService: HotkeyService
    ) {}

    ngOnInit(): void {
        // Register hotkey for "Cancel" action (Ctrl + Q)
        this.hotkeyService.registerHotkey('ctrl+q', 'Cancel action', () => this.onCancel());
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}
