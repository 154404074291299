import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UserService } from '@intranet/modules/users/user.service';
import { LoginService } from '@intranet/modules/login/login.service';
import { User } from '../user.types';
import { TogglService } from '../..';
import { HotkeyService } from 'src/app/core/services/hotkey.service';
import { NgForm } from '@angular/forms';

@Component({
    selector: 'user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit {
    @ViewChild('settingsForm') form: NgForm;

    error: string;
    user: User;
    areDetailsUpdated: boolean = false;

    togglUser: string;
    togglPassword: string;

    ngOnInit(): void {
        // Register hotkey for "Save Changes" action (Ctrl + Alt + S)
        this.hotkeyService.registerHotkey('ctrl+alt+s', 'Save Changes action', () => {
            if (this.form?.valid && !this.form?.pristine) {
                this.onSubmit();
            }
        });

        if (this.loginService.userId) {
            this.userService.getUserDetails(this.loginService.userId).subscribe(
                (r) => {
                    if (r.success) {
                        this.user = r.user;
                    } else {
                        this.error = r.error;
                    }
                },
                (error) => (this.error = error)
            );
        }
    }
    constructor(
        private loginService: LoginService,
        private route: ActivatedRoute,
        private router: Router,
        private userService: UserService,
        private togglService: TogglService,
        private hotkeyService: HotkeyService
    ) {}

    onSubmit() {
        this.userService.updateOwnDetails(this.user).subscribe(
            (r) => {
                if (!r.success) {
                    this.error = r.error;
                } else {
                    this.areDetailsUpdated = true;
                }
            },
            (error) => (this.error = error)
        );
    }

    isToggleConnected() {
        return this.togglService.isLoggedIn();
    }

    onTogglSubmit() {
        this.togglService.login(this.togglUser, this.togglPassword).subscribe(
            (r) => {
                console.log('login result', r);
            }
        );
    }

    disconnectToggl() {
        this.togglService.logout();
    }
}
