import { Component, EventEmitter, OnDestroy, Output } from "@angular/core";
import { ActionService } from "../../action.service";
import { Subscription } from "rxjs";
import { JobActionView } from "../..";
import { HotkeyService } from "src/app/core/services/hotkey.service";

@Component({
    selector: 'action-view-toggle',
    templateUrl: './view-toggle.component.html',
    styleUrls: ['./view-toggle.component.scss']
})
export class ActionViewToggleComponent implements OnDestroy  {
    @Output() viewChange = new EventEmitter<JobActionView>();

    subscription: Subscription;
    view: JobActionView;

    get jobActionView() {
        return JobActionView;
    }

    constructor(
        protected actionService: ActionService,
        private hotkeyService: HotkeyService,
      ) {
        this.subscription = this.actionService.view$.subscribe(view => {
            this.view = view;
            this.viewChange.emit(view);
        });

        // Register hotkey for toggling views (Ctrl + Shift + V)
        this.hotkeyService.registerHotkey(
          'ctrl+shift+v',
          'Toggle between views',
          () => this.toggleView()
      );
    }

    toggleView() {
        // Toggle between the views (Board and List)
        const nextView = this.view === JobActionView.Board ? JobActionView.List : JobActionView.Board;
        this.onChangeView(nextView);
  }

    onChangeView(view: JobActionView) {
        this.actionService.view = view;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
