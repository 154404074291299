import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { TooltipModule } from '@intranet/components/tooltip/tooltip.module';
import { SharedModule } from '@intranet/shared/shared.module';

import { UserService } from '@intranet/modules/users';
import { LoginService } from '@intranet/modules/login';

import { HeaderComponent } from './header.component';
import { MatDialogModule } from '@angular/material/dialog';
import { InfoModalComponent } from '../../modules/info/info-modal.component';
import { InfoButtonComponent } from '../../modules/info/info-button.component';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        TooltipModule,
        RouterModule,
        SharedModule,
        MatDialogModule,
    ],
    exports: [HeaderComponent],
    declarations: [
        HeaderComponent,
        InfoModalComponent,
        InfoButtonComponent
    ],
})
export class HeaderModule {}
