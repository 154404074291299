import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';
import { Job, JobSearchQuery, JobService } from '@intranet/modules/jobs';
import { Client, LoginService, TogglService } from '../..';
import { PageEvent } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialog } from '@intranet/shared/alert/alert-dialog.component';
import { Router } from '@angular/router';
import { HotkeyService } from 'src/app/core/services/hotkey.service';

@Component({
    selector: 'jobs-listing',
    templateUrl: './jobs-listing.component.html',
    styleUrls: ['./jobs-listing.component.scss'],
})
export class JobsListingComponent implements OnInit, OnDestroy {
    @Input() hideSearch = false;
    @Input() hideTabs = false;
    @Input() hideHeader = false;
    @Input() showAll: boolean = false;
    @Input() clients: Client[] = [];
    @Input() isGroup: boolean = false;

    isLoading: boolean = false;
    userId: string = '';
    error: any;
    showArchived: boolean = false;
    showDialog = false;
    currentJobs: boolean;
    jobs: Job[] = [];
    jobId: number;
    filterQuery: JobSearchQuery;
    totalResults = 0;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        protected dialog: MatDialog,
        private _jobService: JobService,
        private _loginService: LoginService,
        private togglService: TogglService,
        private router: Router,
        private hotkeyService: HotkeyService
    ) {}

    ngOnInit(): void {
        // Register hotkey for "Add a new Job"
        this.hotkeyService.registerHotkey(
            'ctrl+shift+a',
            'Add a new job',
            () => this.addNewJob()
        );

        if (!this.showAll) {
            this.userId = this._loginService.userId;
        }

        if (!this.hideSearch) {
            combineLatest([this._jobService.filterQuery$])
                .pipe(
                    take(1),
                    tap(([filterQuery]) => {
                        this.filterQuery = {
                            ...filterQuery,
                            clients: this.clients.map((c) => c.clientId),
                        };
                    })
                )
                .subscribe({
                    complete: () => {
                        //this.onFilter(this.filterQuery);
                    },
                });

            this._jobService.filterQuery$
                .pipe(
                    takeUntil(this._unsubscribeAll),
                    tap((filterQuery) => {
                        this.filterQuery = filterQuery;
                    })
                )
                .subscribe();
        }

        this._jobService.jobs$
            .pipe(
                takeUntil(this._unsubscribeAll),
                tap((response) => {
                    this.jobs = response;
                })
            )
            .subscribe();

        this._jobService.jobsTotalResults$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (jobActions) => {
                    this.totalResults = jobActions;
                },
            });
    }

    ngOnDestroy() {
        this._unsubscribeAll.next('');
        this._unsubscribeAll.complete();
        this._jobService.reset();
    }

    // Call the API via the job service
    loadJobs(query?: JobSearchQuery): Observable<Job[]> {
        this.isLoading = true;
        return this._jobService.getJobs({
            ...query,
            archived: this.showArchived,
        });
    }

    // Called by the main filters
    onFilter(filterQuery: JobSearchQuery) {
        this.isLoading = true;
        this.loadJobs({
            ...filterQuery,
            clients: this.clients.map((c) => c.clientId),
            page: 1,
            isPaginating: false,
        }).subscribe({
            complete: () => (this.isLoading = false),
        });
    }

    onPaginate(e: PageEvent) {
        this.loadJobs({
            ...this.filterQuery,
            page: e.pageIndex + 1,
            isPaginating: true,
        }).subscribe({
            complete: () => {
                this.isLoading = false;
                document.getElementById('page').scrollTo(0, 0);
            }
        });
    }

    onArchived({ job, error }: { job: Job, error: string }) {
        if (error) {
            this.dialog.open(AlertDialog, {
                width: '320px',
                data: {
                    title: 'Cannot archive job',
                    message: error,
                    confirm: true,
                    confirmTitle: 'OK',
                },
            });
        } else {
            this.isLoading = true;
            this.loadJobs({ ...this.filterQuery }).subscribe({
                complete: () => (this.isLoading = false),
            });
        }
    }

    onSort(prop: string) {
        this.isLoading = true;

        const { sortBy: prevSortBy, sortDir: prevSortDir } = this.filterQuery;

        this.loadJobs({
            ...this.filterQuery,
            sortBy: prop,
            sortDir:
                prop === prevSortBy
                    ? prevSortDir === 'asc'
                        ? 'desc'
                        : 'asc'
                    : 'asc',
        }).subscribe({
            complete: () => (this.isLoading = false),
        });
    }

    onJobManageChanged() {
        this.showDialog = false;
        this.onFilter(this.filterQuery);
    }

    // Gets status from tabs and sets the listing ID
    onStatus(status: string): void {
        this.showArchived = status === 'archived';
        this.loadJobs({
            ...this.filterQuery,
            page: 1,
            isPaginating: false,
        }).subscribe({
            complete: () => (this.isLoading = false),
        });
    }

    onClickToggle(toggle: boolean): void {
        this.jobId = null;
        this.showDialog = toggle;
    }

    addNewJob() {
        this.onClickToggle(true);
        // if (this.togglService.isLoggedIn()) {
        //     this.onClickToggle(true);
        // } else {
        // this.router.navigateByUrl('/users/settings');
        // }
    }

    onEdit(job) {
        this.jobId = job.jobId;
        this.showDialog = true;
    }
}
