import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { InfoModalComponent } from '../../modules/info/info-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private infoModalRef: MatDialogRef<InfoModalComponent> | null = null;

  constructor(private dialog: MatDialog) {}

  openInfoModal(): void {
    if (this.infoModalRef) {
      return; // Prevent opening another instance
    }

    this.infoModalRef = this.dialog.open(InfoModalComponent, {
      width: '800px',
      disableClose: false,
    });

    this.infoModalRef.afterClosed().subscribe(() => {
      this.infoModalRef = null;
    });
  }

  isInfoModalOpen(): boolean {
    return this.infoModalRef !== null;
  }
}
