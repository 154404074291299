import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';

import { LoginService } from '@intranet/modules/login/login.service';
import { ClientsService } from '../clients.service';
import { Client, ClientAddress } from '../client.types';
import { HotkeyService } from 'src/app/core/services/hotkey.service';

@Component({
    selector: 'client-add-address',
    templateUrl: './client-add-address.component.html',
})
export class ClientAddAddressComponent implements OnInit {
    @Input() showDialog;
    @Input('action') dialogAction: string;
    @Input() addressInput: number;
    @Output() close: EventEmitter<boolean> = new EventEmitter();
    @Output() update: EventEmitter<any> = new EventEmitter();
    @Output() delete: EventEmitter<any> = new EventEmitter();
    @ViewChild('addressForm') addressForm: NgForm;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private clientsService: ClientsService,
        private loginService: LoginService,
        private hotkeyService: HotkeyService,
    ) {}

    client: Client;
    address: ClientAddress;
    error: string | null;
    title: string;
    editAddress: boolean;

    ngOnInit(): void {
        // Register hotkey for "Cancel" action (Ctrl + Q)
        this.hotkeyService.registerHotkey('ctrl+q', 'Cancel action', () => this.closeModal());

         // Register hotkey for "Save Address" action (Ctrl + Alt + S)
          this.hotkeyService.registerHotkey('ctrl+alt+s', 'Save address', () => {
              if (this.addressForm?.valid && !this.addressForm?.pristine) {
                  this.onSubmit();
              }
          });

        this.route.paramMap
            .pipe(
                switchMap((p) => {
                    let addressId = p.get('addressid');
                    if (addressId) {
                        return this.clientsService.getClientAddress(+addressId);
                    } else {
                        // Enables edit address via modal using Input() addressInput
                        if (this.addressInput) {
                            return this.clientsService.getClientAddress(
                                this.addressInput
                            );
                        }
                    }
                    let clientId = p.get('clientid');
                    if (!clientId) {
                        throw new Error('You need a client Id');
                    }
                    return this.clientsService.initClientAddress(+clientId);
                })
            )
            .subscribe({
                next: (result) => {
                    if (result.success) {
                        this.client = result.client;
                        this.address = result.clientAddress;
                        this.error = '';

                        let addTitle =
                            'Add New Address for ' + this.client.clientName;
                        let editTitle =
                            'Edit Address for ' + this.client.clientName;

                        if (this.dialogAction != null) {
                            // Retrieves action input to determine the title
                            if (this.dialogAction == 'add') {
                                this.title = addTitle;
                            }
                            if (this.dialogAction == 'edit') {
                                this.title = editTitle;
                                this.editAddress = true;
                            }
                        } else {
                            if (
                                this.client &&
                                this.address &&
                                this.address.clientAddressId == 0
                            ) {
                                this.title = addTitle;
                            }
                            if (
                                this.client &&
                                this.address &&
                                this.address.clientAddressId != 0
                            ) {
                                this.title = editTitle;
                                this.editAddress = true;
                            }
                        }
                    } else if (result.error) {
                        throw new Error(result.error);
                    } else {
                        throw new Error(
                            'An error occurred fetching the address'
                        );
                    }
                },
                error: (error) => {
                    this.error = error;
                },
            });
    }

    onSubmit() {
        var serviceCall = !this.address.clientAddressId
            ? this.clientsService.addClientAddress(this.address)
            : this.clientsService.updateClientAddress(this.address);

        serviceCall.subscribe(
            (result) => {
                if (result.success) {
                    //this.router.navigate([`/clients/dashboard/${result.address.clientId}/detail`]);
                    this.update.emit(null);
                    this.closeModal();
                } else {
                    throw new Error(result.error);
                }
            },
            (error) => (this.error = error)
        );
    }

    onDelete(address) {
        this.closeModal();

        // Triggers Delete Address confirmation modal in parent with current address
        this.delete.emit(address);
    }

    closeModal() {
        // Retrieves showDialog input, turns to false and returns back to parent in order to close modal
        this.showDialog = false;
        this.close.emit(this.showDialog);
    }
}
