import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Finance, FinanceMargin, FinanceMarginStatus, FinanceService } from '@intranet/modules/finance';
import { Job, JobMarginUpdate } from '../../job.types';
import { FormBuilder, FormGroup, FormArray, AbstractControl, ValidationErrors } from '@angular/forms';
import { financeStatusLabel, parseFinanceNumber } from '@intranet/modules/finance/finance.helpers';
import { HotkeyService } from 'src/app/core/services/hotkey.service';

@Component({
    selector: 'job-finance-margins',
    templateUrl: './job-finance-margins.component.html',
    styleUrls: ['./job-finance-margins.component.scss']
})
export class JobFinanceMarginsComponent implements OnInit {
    @Input() job: Job;
    @Output() cancel = new EventEmitter();
    @Output() save = new EventEmitter<JobMarginUpdate>();
    isLoading = false;
    finances: {
        all: Finance[],
        estimates: Finance[],
        invoices: Finance[],
        additional: FinanceMargin[]
    } = { all: [], estimates: [], invoices: [], additional: []};


    form: FormGroup;

    constructor(private financeService: FinanceService,
                private formBuilder: FormBuilder,
                private hotkeyService: HotkeyService
    ) { }

    ngOnInit(): void {
        // Register hotkey for "Cancel" action (Ctrl + Q)
        this.hotkeyService.registerHotkey('ctrl+q', 'Cancel action', () => this.onCancel());

        // Register hotkey for "Save" action (Ctrl + Alt + S)
        this.hotkeyService.registerHotkey('ctrl+alt+s', 'Save action', () => {
            if (this.form?.valid) {
                this.onSave();
            }
        });
        
        this.loadFinances();
    }

    addFinanceToForm(finances: Finance[], formSection: string) {
        finances.forEach((estimate) => {
            const margins = this.formBuilder.array([]);
            estimate.financeMargins.forEach((margin) => {
                margins.push(this.formBuilder.group({
                    month: [margin.month],
                    status: [margin.status],
                    value: [margin.value],
                    included: [margin.included],
                    description: [margin.description]
                }));
            });

            (this.form.get(formSection) as FormArray).push(this.formBuilder.group({
                versionid: [estimate.version.id],
                estimateNumber: [this.getFinanceNumber(estimate.financeNo, estimate.financeType,estimate.version.number)],
                date: [estimate.dateCreated],
                status: [estimate.version.financeStatusCode],
                isRejected: [estimate.version.isRejected],
                subtotal: [estimate.version.subTotal],
                isAdditional: [false],
                margins: margins
            }));
        });

    }

    validateFinanceTotals(group: AbstractControl) : ValidationErrors | null {
        let isValid = true;
        let value = group.value;
        let invoices = value.invoices.concat(value.estimates);
        invoices.forEach((estimate) => {
            const subtotal = estimate.subtotal;
            const margins = estimate.margins;
            const total = margins.reduce((sum:number, x) => sum + (+x.value), 0);
            isValid = isValid && subtotal >= total;
        });
        return isValid ? null : { 'total': 'Total margin cannot exceed subtotal' };
    }

    loadFinances(){
        this.isLoading = true;
        this.financeService.getFinanceMarginsForJob(this.job.jobId).subscribe((result) => {
            let finances = result.finances;
            this.finances.all = finances;
            this.finances.estimates = finances.filter((finance) => finance.financeType === 1);
            this.finances.invoices = finances.filter((finance) => finance.financeType === 2);
            this.finances.additional = result.additionalMargins.margins;
            
            this.form = this.formBuilder.group({
                estimates: this.formBuilder.array([]),
                invoices: this.formBuilder.array([]),
                additional: this.formBuilder.array([])
            }, { validators: this.validateFinanceTotals });

            this.addFinanceToForm(this.finances.estimates, 'estimates');
            this.addFinanceToForm(this.finances.invoices, 'invoices');

            // To make the additional margins the same shape as the estimates and invoices
            // we need to add a group to the form array which then contains the margins
            let marginControls = this.formBuilder.array([]);
            this.finances.additional.forEach((margin) => {
                marginControls.push(this.formBuilder.group({
                    month: [margin.month],
                    status: [margin.status],
                    value: [margin.value],
                    included: [margin.included],
                    description: [margin.description]
                }));
            });
            (this.form.get('additional') as FormArray).push(this.formBuilder.group({
                isAdditional: [true],
                margins: marginControls
            }));

            // this.estimates.forEach((estimate) => {
            //     const margins = this.formBuilder.array([]);
            //     estimate.financeMargins.forEach((margin) => {
            //         margins.push(this.formBuilder.group({
            //             month: [margin.month],
            //             status: [margin.status],
            //             value: [margin.value],
            //             included: [margin.included]
            //         }));
            //     });

            //     (this.form.get('estimates') as FormArray).push(this.formBuilder.group({
            //         versionid: [estimate.version.id],
            //         estimateNumber: [`E${estimate.financeNo}/${estimate.version.number}`],
            //         date: [estimate.dateCreated],
            //         status: [estimate.version.financeStatusCode],
            //         value: [estimate.version.subtotal],
            //         margins: margins
            //     }));
            // });

            /*
            <form>
                <estimates>
                    <estimate>
                        <versionid>
                        <esitmatenumber>
                        <date>
                        <status>
                        <value>
                        <margins>
                            <margin>
                                <month>
                                <status>
                                <value>
                                <included>
                            </margin>
                            ...
                        </margins>
                    </estimate>
                    ...
                </estimates>
                <invoices>
                    <estimate>
                        <versionid>
                        <esitmatenumber>
                        <date>
                        <status>
                        <value>
                        <margins>
                            <margin>
                                <month>
                                <status>
                                <value>
                                <included>
                            </margin>
                            ...
                        </margins>
                    </estimate>
                    ...
                </invoices>
            </form>
            */


            this.isLoading = false;
        });
    }

    get financeGroups() : { name: string, formArray: FormArray }[] {
        return [
            { name: 'Estimates', formArray: this.form.get('estimates') as FormArray },
            { name: 'Invoices', formArray: this.form.get('invoices') as FormArray }
        ];
    }

    showFormData() {
    }

    onSave() {
        //console.log('Save', this.form.value);
        var updatedata = this.form.value;
        var allEstimates = updatedata.estimates.concat(updatedata.invoices);
        var additional = updatedata.additional[0];
        let jobMarginUpdate: JobMarginUpdate = {
            jobId: this.job.jobId,
            estimates: allEstimates.map((estimate) => {
                return {
                    financeVersionId: estimate.versionid,
                    margins: estimate.margins.map((margin) => {
                        return {
                            month: margin.month,
                            status: margin.status,
                            value: margin.value,
                            included: margin.included,
                            description: margin.description
                        }
                    })
                }
            }),
            additional: additional.margins.map((margin) => {
                return {
                    month: margin.month,
                    status: margin.status,
                    value: margin.value,
                    included: margin.included,
                    description: margin.description
                }
            })

        };
        this.save.emit(jobMarginUpdate);
    }

    showRebuild() {
    }

    getFinanceStatus(financeStatusCode: string, isRejected: boolean = false) {
        return financeStatusLabel(
            this.financeService.financeStatuses$,
            financeStatusCode,
            isRejected
        );
    }

    onMonthSelected(event: any, margin: FormGroup) {
        margin.patchValue({ month: event });
        this.form.markAsDirty();
    }

    onMonthCleared(event: any, margin: FormGroup) {
        margin.patchValue({ month: null });
        this.form.markAsDirty();
    }
    onRemove(estimate: FormGroup, index: number) {
        (estimate.get('margins') as FormArray).removeAt(index);
        this.form.markAsDirty();
    }
    onAdd(estimate: FormGroup) {
        let now = new Date();
        let month = new Date(now.getFullYear(), now.getMonth(), 1);
        (estimate.get('margins') as FormArray).push(this.formBuilder.group({
            month: [month],
            status: [FinanceMarginStatus.Opportunity],
            value: [null],
            included: [true],
            description: [null]
        }));
        this.form.markAsDirty();
    }

    getMarginTotal(estimate: FormGroup) : number {
        //return 192;
        //console.log(estimate.get('margins').value);
        
        return estimate.get('margins').value.reduce((sum:number, x) => sum + (+x.value), 0);
    }

    isMarginOverflow(estimate: FormGroup) : boolean {
        if (!estimate.value.isAdditional) {
            let margintotal = estimate.get('margins').value.reduce((sum:number, x) => sum + (+x.value), 0);
            let subtotal = estimate.get('subtotal').value;
            return margintotal > subtotal;
        } else {
            return false;
        }
    }

    onTest() {
        console.log(this.form.value);
    }

    onCancel() {
        this.cancel.emit();
    }

    getFinanceNumber(financeNo, financeType, versionNumber) {
        return parseFinanceNumber(financeNo, financeType, versionNumber);
    }

}