import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import * as moment from 'moment';
import * as _ from 'lodash';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { tap, map, switchMap, take } from 'rxjs/operators';

import { LoginService } from '@intranet/modules/login';
import { ClientsService } from '@intranet/modules/clients';
import { FinanceService } from '../finance.service';
import {
    Currency,
    Finance,
    FinanceContact,
    FinanceMarginStatus,
    FinanceStatus,
} from '../finance.types';
import { FinanceDeleteDialog } from '../dialogs/delete/finance-delete-dialog.component';
import { FinanceApproveDialog } from '../dialogs/approve/finance-approve-dialog.component';
import {
    canLoggedInUserActionNextStep,
    convertFinanceTypeToName,
    convertNameToFinanceType,
    navigateToConvert,
    parseFinanceNumber,
} from '../finance.helpers';
import { FinanceAlertDialog } from '../dialogs/alert/finance-alert-dialog.component';
import { Client, Contact } from '@intranet/modules/clients/client.types';
import { orderClientsByGroupName } from '@intranet/modules/clients/client.helpers';
import { Job, JobService, JobStatus } from '@intranet/modules/jobs';
import { CurrencyPipe } from '@angular/common';
import { HotkeyService } from 'src/app/core/services/hotkey.service';

@Component({
    selector: 'finance',
    templateUrl: './finance-manage.component.html',
    styleUrls: ['./finance-manage.component.scss']
})
export class FinanceManageComponent implements OnInit, OnDestroy {
    private readonly TERMS_QUOTE1_DEFAULT =
        'Valid for 30 days from date of estimate';
    private readonly TERMS_QUOTE2_DEFAULT =
        'Amends to the brief may incur additional charges';
    private readonly TERMS_INVOICE_DEFAULT =
        'Payment terms: 30 days from date of invoice';

    @ViewChild(NgForm) ngForm: NgForm;
    financeItemForm: FormGroup;
    financeMarginForm: FormGroup;
    isLoading: boolean = false;
    error: any;
    financeVersionId?: number;
    finance: Finance = {
        user: {},
        version: {
            dateDisplay: new Date(),
            terms1: this.TERMS_QUOTE1_DEFAULT,
            terms2: this.TERMS_QUOTE2_DEFAULT,
            financeStatusCode: 'INTDFT',
        },
    };
    financeReset: Finance;
    isCopying: boolean = false;
    isCopyToInvoice: boolean = false;
    isCopyToCredit: boolean = false;
    clients: [Client[], Client[]] = [[], []];
    allClients: Client[];
    contact: FinanceContact;
    contacts: Contact[] = [];
    jobs: Job[] = [];
    currencies: Currency[] = [];
    status: FinanceStatus;
    nextStatus: FinanceStatus;
    subtotal: number;
    vat: number;
    total: number;
    margin: number;
    itemsValid: boolean = false;
    marginValid: boolean = true;
    itemsChanged: boolean = false;
    marginsChanged: boolean = false;
    pdfReady: boolean = false;
    vatDisabled: boolean = false;
    financeOrAdminEditOverride = false;
    backToJobDetail = false;
    showMarginSelection = false;
    marginsToCopy: boolean[] = [];
    marginIds: number[] = [];
    private cancelDialogRef: any = null;
    private modalOpen: boolean = false;

    constructor(
        public dialog: MatDialog,
        private _activatedRoute: ActivatedRoute,
        private _financeService: FinanceService,
        private _clientService: ClientsService,
        private _loginService: LoginService,
        private _jobService: JobService,
        private _router: Router,
        private currencyPipe: CurrencyPipe,
        private hotkeyService: HotkeyService,
    ) {
        // when converting an estimate to an invoice, we need to copy some margins
        //this.marginIds = this._activatedRoute.snapshot.queryParams.mid.map(n => +n);
        let copywhichmargins = this._activatedRoute.snapshot.queryParams.m;
        // given copywhichmargins contains an array of strings of either "true" or "false"
        // we need to convert these to booleans
        if (copywhichmargins){
            this.marginsToCopy = copywhichmargins.map(s => s === "true");
        }

        // We edit based on the version rather than the parent finance entity
        this.financeVersionId =
            +this._activatedRoute.snapshot.paramMap.get('id');

        // Copying 1-1, or from a quote/invoice to invoice/credit note (they all copy)
        this.isCopying = this._activatedRoute.snapshot.data?.copy;

        // Specifically the type of copy is to invoice is true
        this.isCopyToInvoice =
            this._activatedRoute.snapshot.data?.copyToInvoice;

        // Specifically the type of copy is to credit note is true
        this.isCopyToCredit = this._activatedRoute.snapshot.data?.copyToCredit;

        // Set query string params
        const queryParams = this._activatedRoute.snapshot.queryParams;
        const clientId = queryParams.clientId ? parseInt(queryParams.clientId) : null;
        const jobId = queryParams.jobId ? parseInt(queryParams.jobId) : null;

        // When clicking back, if we have a jobId, we need to go back to the job detail
        if (jobId) {
            this.backToJobDetail = true;
        }

        if (!this.financeVersionId) {
            if (queryParams) {
                this.finance.clientId = clientId;
                this.finance.jobId = jobId;
            }

            this.finance.financeType = +convertNameToFinanceType(
                // If we are creating a credit note, we need to manually override
                // the financeType as credit notes are displayed in the invoice section
                // (where financeType is otherwise derived from)
                this.isCopyToCredit
                    ? 'creditnote'
                    : this._activatedRoute.snapshot.paramMap.get('financeType')
            );
        }
    }

    ngOnInit() {

      // Register hotkey for "Cancel" action (Ctrl + Q)
      this.hotkeyService.registerHotkey('ctrl+q', 'Cancel action', () => {
          if (!this.modalOpen) {
              this.handleCancel();
          }
      });

      // Track dialog open/close state
      this.dialog.afterOpened.subscribe(() => (this.modalOpen = true));
      this.dialog.afterAllClosed.subscribe(() => {
          this.modalOpen = false;
          // Ensure the page hotkey works after the modal closes
          this.hotkeyService.unregisterHotkey('ctrl+q'); // Prevent duplicates
          this.hotkeyService.registerHotkey('ctrl+q', 'Cancel action', () => {
              if (!this.modalOpen) {
                  this.handleCancel();
              }
          });
      });

      // Register hotkey for "Download" action (Ctrl + D)
      this.hotkeyService.registerHotkey('ctrl+d', 'Download action', () => {
          if (
              this.pdfReady &&
              ((this.finance.version.hasApprovals && this.finance.financeId) ||
                  (this.finance.financeType === 3 &&
                      this.finance.version.financeStatusCode !== 'INTDFT'))
          ) {
              this.onDownload();
          } else {
              console.warn('PDF is not ready or conditions are not met.');
          }
      });


      // Register hotkey for "Save" action (Ctrl + Alt + S)
      this.hotkeyService.registerHotkey('ctrl+alt+s', 'Save', () => {
          if (
              !this.nextStatus &&
              !this.finance.version.hasApprovals &&
              !this.finance.readonly &&
              this.ngForm.valid &&
              this.itemsValid &&
              this.marginValid &&
              (!this.ngForm.pristine || this.isCopying)
          ) {
              // Trigger primary "Save" button
              this.onSubmit();
          } else if (
              !this.finance.readonly &&
              (!this.ngForm.pristine || this.itemsChanged || this.marginsChanged) &&
              (this.nextStatus || this.financeOrAdminEditOverride) &&
              this.ngForm.valid &&
              (this.itemsChanged || this.marginsChanged)
          ) {
              if (!this.isCopying) {
                  // Trigger "Save changes" button
                  this.onSubmit();
              }
          } else if (
              !this.isCopying &&
              this.marginsChanged &&
              !this.itemsChanged &&
              this.finance.version.hasApprovals
          ) {
              // Trigger "Save Margin Changes" button
              this.onUpdateMargin();
          } else if (
              this.finance.readonly &&
              (!this.ngForm.pristine || this.marginsChanged)
          ) {
              // Trigger readonly "Save Margin Changes" button
              this.onUpdateMargin();
          }
      });

        combineLatest([
            this._clientService.clients$,
            this._clientService.myClients$,
            this._financeService.currencies$
        ])
            .pipe(
                tap(([allClients, myClients, currencies]) => {
                    const [pinnedClients, restClients] =
                        orderClientsByGroupName(
                            allClients.filter(c => !c.archive),
                            myClients.map((c) => c.clientId),
                            true
                        );

                    this.allClients = allClients;
                    this.clients = [
                        pinnedClients as Client[],
                        restClients as Client[],
                    ];

                    this.currencies = currencies;

                    this.financeReset = _.cloneDeep(this.finance);

                    if (this.financeVersionId) {
                        this.loadFinance();
                    } else {
                        // Add a default item to get started with
                        this.finance.financeItems = [
                            { content: '', vat: true, value: null, order: 0 },
                        ];

                        this.finance.financeMargins = []

                        // Default the reference to the logged in users intitals
                        this.finance.version.reference =
                            this._loginService.userInitials;

                        // If redirected from a job
                        if (this.finance.clientId && this.finance.jobId) {
                            this.onClientChange(this.finance.clientId);
                        }
                    }
                })
            )
            .subscribe();
    }

    loadFinance() {
        this.isLoading = true;

        this._financeService
            .getFinance(this.financeVersionId)
            .pipe(
                take(1),
                switchMap((finance) => {
                    //console.log('Finance', finance);
                    if (!this.contacts.length) {
                        return this.loadJobsAndContacts(finance.clientId).pipe(
                            map(([jobs, contacts]) => ({ finance, jobs, contacts }))
                        );
                    } else {
                        return of(this.contacts).pipe(
                            map(contacts => ({ finance, jobs: [] as Job[], contacts }))
                        );
                    }
                }),
                switchMap(({ finance, jobs, contacts }) => {
                    this.mapFinance(finance);
                    if (!finance.version.isRejected && !this.isCopying) {
                        return this._financeService
                            .getNextStatus(
                                this.finance.version.financeStatusCode,
                                this.finance.financeType
                            )
                            .pipe(
                                map((nextStatus) => ({
                                    finance,
                                    jobs,
                                    contacts,
                                    nextStatus,
                                }))
                            );
                    } else {
                        return of({
                            finance,
                            jobs,
                            contacts,
                            nextStatus: null,
                        });
                    }
                }),
                tap(({ finance, nextStatus }) => {
                    this.finance.readonly =
                        !this.isCopying &&
                        (finance.version.isRejected ||
                            !finance.version.isLatest ||
                            !nextStatus);

                    // Allow finance/admin to edit an completed invoice (creates a new version)
                    if (
                        this.finance.financeType === 2 &&
                        this._loginService.checkAuth(['finance', 'admin'])
                    ) {
                        this.finance.readonly = false;
                        this.financeOrAdminEditOverride = true;
                    }
                    this.nextStatus = nextStatus;
                })
            )
            .subscribe({
                next: () => {
                    this.isLoading = false;
                },
                error: (error) => {
                    this.showError(error);
                    this.isLoading = false;
                },
            });
    }

    loadJobsAndContacts(clientId: number): Observable<[Job[], Contact[]]> {
        this.isLoading = true;
        return forkJoin([
            this._jobService.getJobs({ clientId, page: 1, status: JobStatus.Live, resultsPerPage: -1  }),
            this._clientService.getContactsByClientId(clientId)
        ]).pipe(
            tap(([jobs, contacts]) => {
                // Jobs
                const jobsResult = jobs ?? [];
                this.jobs = jobsResult;

                // Contacts
                const contactsResult = contacts ?? [];
                this.contacts = contactsResult;
                this.finance.version.contactId =
                    contactsResult.length === 1 && !this.financeVersionId
                        ? contactsResult[0].contactId
                        : null;

                this.isLoading = false;
            })
        );
    }

    mapFinance(finance: Finance) {
        this.finance = finance;
        this.financeVersionId = finance.version.id;
        this.finance.hasAnyApprovals =
            finance.version.hasApprovals || finance.version.number > 1;
        this.finance.version.currencyCode = this.currencies.find(
            (c) => c.currencyId === finance.version.currencyId
        )?.code;

        this.pdfReady = true;

        let client = this.allClients.find(
            (c) => c.clientId === this.finance.clientId
        );

        // If copying, override some props
        if (this.isCopying) {
            this.finance.hasAnyApprovals = false;
            this.finance.version.hasApprovals = false;
            this.finance.version.isRejected = false;
            this.finance.version.financeStatusCode = 'INTDFT';
            this.finance.version.dateDisplay = new Date();
        }

        // If copying to invoice we need to switch the type from quote to invoice
        if (this.isCopyToInvoice) {
            this.finance.financeType = 2;
            this.finance.version.quoteRef = `E-${this.finance.financeNo}/${this.finance.version.number}`;
            this.finance.version.terms1 = client.invoicePaymentTerms ?? this.TERMS_INVOICE_DEFAULT;

            // we also need to only copy the margins that were selected
            this.finance.financeMargins = this.finance.financeMargins.filter((m, i) => this.marginsToCopy[i]);
        }

        // If copying to credit note, we need to switch the type from invoice to credit note
        if (this.isCopyToCredit) {
            this.finance.financeType = 3;
        }

        this.financeReset = _.cloneDeep(this.finance);

        this.contact = this.mapContact(
            this.finance.version.contactId,
            this.finance.clientId
        );

        this.finance.clientName = client.clientName;
        this.checkVatEnabled(client);
        this.resetForm(this.finance);
        this.marginsToCopy = this.finance.financeMargins.map(m => m.included);
    }

    mapContact(contactId: any, clientId: any): FinanceContact {
        let result = null;
        let contact = this.contacts.find(
            (c) => c.contactId === parseInt(contactId)
        );
        let client = this.allClients.find(
            (c) => c.clientId === parseInt(clientId)
        );

        result = <FinanceContact>{
            name: contact
                ? `${contact.firstName} ${contact.surname}`
                : client.clientName,
            email: contact ? contact.email : '',
        };

        if (contact && contact.clientAddress) {
            result.address1 = contact.clientAddress.address1;
            result.address2 = contact.clientAddress.address2;
            result.address3 = contact.clientAddress.address3;
            result.county = contact.clientAddress.county;
            result.postcode = contact.clientAddress.postcode;
            result.country = contact.clientAddress.country;
        } else if (client && client.addresses.length) {
            let address = client.addresses.find((a) => a.isFinance);
            if (!address) address = client.addresses.find((a) => a.isPrimary);
            if (!address) address = client.addresses[0];
            result.address1 = address.address1;
            result.address2 = address.address2;
            result.address3 = address.address3;
            result.county = address.county;
            result.postcode = address.postcode;
            result.country = address.country;
        }

        return result;
    }

    resetForm(finance: Finance) {
        this.ngForm &&
            this.ngForm.resetForm({
                clientId: finance.clientId,
                clientRef: finance.version.clientRef,
                contact: finance.version.contactId,
                currency: finance.version.currencyId,
                dateDisplay: finance.version.dateDisplay,
                jobId: finance.jobId,
                reference: finance.version.reference,
                referenceExtra: finance.version.referenceExtra,
                margin: finance.version.margin,
                terms1: finance.version.terms1,
                terms2: finance.version.terms2,
                title: finance.version.title,
            });
        if (this.financeItemForm && this.financeItemForm.dirty) {
            //console.log('items dirty');
            this.financeItemForm.reset({ items: finance.financeItems });
        }
        //console.log('finance.financeMargins', finance.financeMargins, this.financeMarginForm?.dirty);
        if (this.financeMarginForm && this.financeMarginForm.dirty) {
            //console.log('margins dirty');
            this.financeMarginForm.reset({ items: finance.financeMargins });
        }
        if (this.ngForm)
        {
            this.ngForm.form.markAsPristine();
            this.ngForm.form.markAsUntouched();
        }
    }

    getFinanceNumber(financeId, financeType, versionNumber) {
        return parseFinanceNumber(financeId, financeType, versionNumber);
    }

    onClientChange(clientId: any) {
        let client = this.allClients.find(
            (c) => c.clientId === parseInt(clientId)
        );

        // Disable VAT if client is T0 or T22
        this.checkVatEnabled(client);

        // Default to clients currency
        if (client && client.currencyId) {
            this.finance.version.currencyId = client.currencyId;
            this.finance.version.currencyCode = this.currencies.find(
                (c) => c.currencyId === client.currencyId
            )?.code;
        }

        // Set client default terms
        if (client) {
            // Quote
            if (this.finance.financeType === 1) {
                this.finance.version.terms1 = client.quoteTerms1
                    ? client.quoteTerms1
                    : this.TERMS_QUOTE1_DEFAULT;
                this.finance.version.terms2 = client.quoteTerms2
                    ? client.quoteTerms2
                    : this.TERMS_QUOTE2_DEFAULT;
            }

            // Invoice
            if (this.finance.financeType === 2) {
                this.finance.version.terms1 = client.invoicePaymentTerms
                    ? client.invoicePaymentTerms
                    : this.TERMS_INVOICE_DEFAULT;
            }
        }

        if (this.finance.clientId) {
            this.loadJobsAndContacts(this.finance.clientId).subscribe();
        }

        // Store ref to client name for future UI refs
        this.finance.clientName = client.clientName;
    }

    onJobChange(event: any) {
        let jobId = this.ngForm.form.get('jobId');
        let job = this.jobs.find((j) => j.jobId === parseInt(jobId.value));
        if (job) {
            this.ngForm.form.patchValue({ title: job.jobTitle });
        }
    }

    checkVatEnabled(client) {
        if (
            client &&
            (client.vatDefaultCode === 'T0' || client.vatDefaultCode === 'T22')
        ) {
            this.vatDisabled = true;
        } else {
            this.vatDisabled = false;
        }
    }

    onConvertToType(financeType: number) {
        this.finance.financeType = financeType;
    }

    onSubmit() {
        this.isLoading = true;
        this.itemsChanged = false;
        this.marginsChanged = false;

        const mapNextStatus = (finance) =>
            this._financeService
                .getNextStatus(
                    finance.version.financeStatusCode,
                    this.finance.financeType
                )
                .pipe(
                    map((nextStatus) => ({
                        finance,
                        nextStatus,
                    }))
                );

        if (this.financeVersionId && !this.isCopying) {
            //console.log('Updating', this.finance);
            this._financeService
                .update(this.finance)
                .pipe(switchMap(mapNextStatus), take(1))
                .subscribe({
                    next: ({ finance, nextStatus }) => {
                        this.mapFinance(finance);
                        this.nextStatus = nextStatus;
                        this._router.navigateByUrl(
                            `/finance/${convertFinanceTypeToName(
                                finance.financeType,
                                true
                            )}/${finance.version.id}`
                        );
                    },
                    complete: () => {
                        this.isLoading = false;
                        this.generatePdf(this.finance.version.id);
                    },
                    error: (error) => {
                        this.showError(error);
                        this.isLoading = false;
                    },
                });
        } else {
            const create = () => {
                this._financeService
                    .create(this.finance)
                    .pipe(switchMap(mapNextStatus), take(1))
                    .subscribe({
                        next: ({ finance, nextStatus }) => {
                            this.mapFinance(finance);
                            this.nextStatus = nextStatus;
                            this._router.navigateByUrl(
                                `/finance/${convertFinanceTypeToName(
                                    finance.financeType,
                                    true
                                )}/${finance.version.id}`
                            );
                            this.generatePdf(finance.version.id);
                        },
                        complete: () => {
                            this.isLoading = false;
                        },
                        error: (error) => {
                            this.showError(error);
                            this.isLoading = false;
                        },
                    });
            };

            // Check the user is defo sure they want to create a credit note
            if (this.finance.financeType === 3) {
              this.cancelDialogRef = this.dialog.open(FinanceAlertDialog, {
                    width: '320px',
                    data: {
                        title: 'Creating a Credit Note',
                        message: 'Are you sure?',
                        confirm: true,
                        confirmTitle: 'Yes',
                    },
                });

                this.cancelDialogRef.afterClosed().pipe(
                  tap((result) => {
                      if (result) {
                          create();
                      } else {
                          this.isLoading = false;
                      }
                  })
              ).subscribe({
                  complete: () => {
                      this.cancelDialogRef = null; // Clear the reference when dialog closes
                  }
              });
            } else {
                create();
            }
        }
    }

    onUpdateMargin() {
        this.isLoading = true;
        this.marginsChanged = false;
        this._financeService.updateMarginOnly(this.finance)
            .subscribe(result =>
                {
                    this.isLoading = false;
                    this.resetForm(this.finance);
                    this.marginsToCopy = this.finance.financeMargins.map(m => m.included);
                });
    }

    generatePdf(financeVersionId: number) {
        this.pdfReady = false;

        this._financeService
            .createPdf(financeVersionId)
            .pipe(
                take(1),
                tap((success) => {
                    if (success) {
                        this.pdfReady = true;
                    }
                })
            )
            .subscribe();
    }

    canActionNextStep(finance: Finance) {
        let isFinance = this._loginService.checkAuth(['finance']);
        let isAdmin = this._loginService.checkAuth([ 'admin']);
        return canLoggedInUserActionNextStep(
            finance,
            this.nextStatus,
            isFinance,
            isAdmin
        );
    }

    onNextStatus(
        financeId: number,
        versionId: number,
        nextStatus: FinanceStatus
    ) {
        if (nextStatus.requiresApproval) {
            if (
                !this._loginService.checkAuth(['finance', 'manager', 'admin'])
            ) {
                this.showNoPermissonModal();
            } else {
                this.showApprovalModal(financeId, versionId, nextStatus);
            }
        } else {
            this._financeService
                .createVersionStatus(
                    financeId,
                    versionId,
                    nextStatus.financeStatusCode
                )
                .pipe(
                    tap(() => {
                        this.generatePdf(versionId);

                        // The below code may come back but not required for now (https://visarcdev.atlassian.net/browse/VFS-117)
                        // if (nextStatus.financeStatusCode === 'INTAPL') {
                        //     this.openClientMail({
                        //         subject: `${parseFinanceNumber(
                        //             this.finance.financeNo,
                        //             this.finance.financeType,
                        //             this.finance.version.number
                        //         )} - ${this.finance.clientName} - ${
                        //             this.finance.job.jobTitle
                        //         }`,
                        //         body: `${
                        //             this.finance.version.title ||
                        //             this.finance.job.jobTitle
                        //         }%0d%0a%0d%0a${
                        //             window.location.origin
                        //         }/finance/${convertFinanceTypeToName(
                        //             this.finance.financeType,
                        //             true
                        //         )}/${this.finance.version.id}%0d%0a%0d%0a`,
                        //     });
                        // }
                    })
                )
                .subscribe({
                    complete: () => this.loadFinance(),
                    error: (error) => {
                        this.showError(error);
                        this.isLoading = false;
                    },
                });
        }
    }

    onCreateNextFinanceType(financeType: number, versionId: number) {
        if (financeType === 2) {
            navigateToConvert(versionId, financeType, this._router);
        } else {
            this.showMarginSelection = true;
        }
    }

    onCopy(finance: Finance) {
        this._router.navigateByUrl(
            `/finance/${convertFinanceTypeToName(
                finance.financeType,
                true
            )}/${finance.version.id}/copy`
        );
    }

    showNoPermissonModal() {
        this.dialog.open(FinanceAlertDialog, {
            width: '320px',
            data: {
                title: 'Manager approval required',
            },
        });
    }

    showApprovalModal(
        financeId: number,
        versionId: number,
        nextStatus: FinanceStatus
    ) {
        const dialogRef = this.dialog.open(FinanceApproveDialog, {
            width: '320px',
            data: { finance: this.finance, nextStatus },
        });

        dialogRef
            .afterClosed()
            .pipe(
                switchMap((result) => {
                    if (result) {
                        return this._financeService.createVersionStatus(
                            financeId,
                            versionId,
                            nextStatus.financeStatusCode,
                            result.action === 'reject'
                        );
                    }

                    return of(false);
                })
            )
            .subscribe({
                complete: () => {
                    this.isLoading = false;
                    this.generatePdf(versionId);
                    this.loadFinance();
                },
                error: (error) => {
                    this.showError(error);
                    this.isLoading = false;
                },
            });
    }

    openClientMail({ subject, body }) {
        window.location.href = `mailto:?subject=${subject}&body=${body}`;
    }

    showError(error) {
        let errorMessage = error.message || error;
        this.dialog.open(FinanceAlertDialog, {
            width: '320px',
            data: { title: 'Ooops', message: errorMessage },
        });
    }

    onDatePicked(res: string) {
        let m = moment(res);
        //console.log(m.format('YYYY-MM-DD'));
    }

    onChangeCurrency($event) {
        let currency = this.currencies.find(
            (c) => c.currencyId === Number($event.target.value)
        );
        if (currency) {
            this.finance.version.currencyId = currency.currencyId;
            this.finance.version.currencyCode = currency.code;
        }
    }

    onFinanceItemsChanged(data) {
        //console.log('onFinanceItemsChanged', data);
        // Update values
        this.finance.financeItems = data.items;
        this.subtotal = data.subtotal;
        this.vat = data.vat;
        this.total = data.total;
        this.itemsValid = data.valid;

        // handle margin as well, which isn't edited by the FinanceItems section
        //this.margin = this.finance.version.margin;

        this.finance.version.subTotal = this.subtotal;
        this.finance.version.vat = this.vat;
        this.finance.version.total = this.total;
        //this.finance.version.margin = this.margin;

        // Check if finance items changed
        this.itemsChanged = data.dirty;

        this.financeItemForm = data.form;
    }

    onFinanceMarginsChanged(data) {
        // console.log('onFinanceMarginsChanged', data);
        let itemAdded = this.finance.financeMargins.length !== data.items.length;
        this.finance.financeMargins = data.items;
        this.margin = data.total;
        this.finance.version.margin = this.margin;

        this.marginsChanged = data.dirty || itemAdded;
        this.financeMarginForm = data.form;
        // see if the total margin is greater than the total
        if (this.margin > this.subtotal) {
            this.marginValid = false;
        } else {
            this.marginValid = true;
        }
    }

    onView() {
        window.open(
            `/finance/downloads/${this.finance.version.id}/view`,
            '_blank'
        );
    }

    onDownload() {
        window.open(
            `/finance/downloads/${this.finance.version.id}/download`,
            '_blank'
        );
    }

    onDelete(finance: Finance): void {
        const dialogRef = this.dialog.open(FinanceDeleteDialog, {
            width: '350px', height: '190px',
            data: { finance },
        });

        dialogRef
            .afterClosed()
            .pipe(
                switchMap((result) => {
                    if (result)
                        return this._financeService
                            .delete(result.financeId, result.fullDelete)
                            .pipe(
                                switchMap(() =>
                                    this._router.navigateByUrl(
                                        `/finance/${convertFinanceTypeToName(
                                            this.finance.financeType,
                                            true
                                        )}`
                                    )
                                )
                            );

                    return of(false);
                })
            )
            .subscribe({
                complete: () => (this.isLoading = false),
                error: (error) => {
                    this.showError(error);
                    this.isLoading = false;
                },
            });
    }

    handleCancel() {
      // Close the Cancel Create dialog if it's open
      if (this.cancelDialogRef) {
          this.cancelDialogRef.close();
          this.cancelDialogRef = null;
          return;
      }

      // Check if "Cancel Copy Margins" should be triggered
      if (this.showMarginSelection) {
          this.onCancelCopyMargins();
          return;
      }

      // Check if "Cancel Create" button logic applies
      if (
          !this.nextStatus &&
          !this.finance.version.hasApprovals &&
          !this.finance.readonly
      ) {
          this.onCancelCreate();
          return;
      }

      // Check for the "Back" button logic
      if (
          !(
              !this.nextStatus &&
              !this.finance.version.hasApprovals &&
              !this.finance.readonly
          )
      ) {
          this.onBack();
          return;
      }

      // Default Cancel fallback (reset form)
      this.onCancel();
  }

    onCancel() {
        this.resetForm(this.financeReset);
    }

    onCancelCreate() {
        if (this.cancelDialogRef) {
            this.cancelDialogRef.close();
            this.cancelDialogRef = null;
            return;
        }

        if (this.ngForm.dirty || this.itemsChanged) {
            this.cancelDialogRef = this.dialog.open(FinanceAlertDialog, {
                width: '320px',
                data: {
                    title: 'Cancel',
                    message: 'By cancelling, you will lose any changes made',
                    confirm: true,
                    confirmTitle: 'Ok',
                },
            });

            this.cancelDialogRef.afterClosed()
                .pipe(
                    tap((result) => {
                        if (result) {
                            this._router.navigateByUrl(
                                `/finance/${convertFinanceTypeToName(
                                    this.finance.financeType,
                                    true
                                )}`
                            );
                        }
                    })
                )
                .subscribe({
                    complete: () => {
                        this.cancelDialogRef = null; // Clean up the reference
                        this.isLoading = false;
                    },
                    error: (error) => {
                        this.showError(error);
                        this.isLoading = false;
                        this.cancelDialogRef = null; // Ensure cleanup on error
                    },
                });
        } else {
            this._router.navigateByUrl(
                `/finance/${convertFinanceTypeToName(
                    this.finance.financeType,
                    true
                )}`
            );
        }
    }

    onBack() {
        if (this.backToJobDetail) {
            this.onGotoJob();
        } else {
            let financeTypePath =
                this.finance.financeType === 3
                    ? 'invoices'
                    : convertFinanceTypeToName(this.finance.financeType, true);
            this._router.navigateByUrl(`/finance/${financeTypePath}`);
        }
    }

    onGotoJob() {
        this._router.navigateByUrl(
            `/jobs/detail/${this.finance.jobId}`
        );
    }

    onCopyMargins() {
        // Get the true/false array of margins to copy
        let mapped = this.finance.financeMargins.map((m, i) => this.marginsToCopy[i]);
        // Close the dialog
        this.showMarginSelection = false;
        // we need to navigate to the new invoice appending the margins to copy
        // we add the true/false flags in the query string with the label m
        this._router.navigate([`/finance/invoices/${this.financeVersionId}/to-invoice`], { queryParams: { m: this.marginsToCopy } });
    }

    onCancelCopyMargins() {
        this.showMarginSelection = false;
    }

    onToggleMarginSelection() {
        // if all margins are false, set all to true, otherwise set all to false
        let value = this.marginsToCopy.filter(m=>m).length === 0;
        for (let i = 0; i < this.marginsToCopy.length; i++) {
            this.marginsToCopy[i] = value;
        }
    }

    ngOnDestroy() {
        // Unregister the hotkey when the component is destroyed
        this.hotkeyService.unregisterHotkey('ctrl+q');
    }
}
