import { Component, Inject, OnInit, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '@intranet/modules/users';
import {
    User,
    UserGroup,
    UserOrGroup,
} from '@intranet/modules/users/user.types';
import { switchMap } from 'rxjs-compat/operator/switchMap';
import { statuses, priorities } from '../../action.utils';
import { ActionStatus } from '../../action.types';
import { Priority } from '@intranet/core/types';
import { HotkeyService } from 'src/app/core/services/hotkey.service';

export interface BulkDialogData {
        scheduleDate?: Date, 
        deadlineDate?: Date,
        clearDeadline?: boolean,
        actionStatus?: ActionStatus,
        waitingOnUserOrGroupId?: string | number,
        priority?: Priority,
        owners?: {userIds: string[];
            groupIds: number[];
        }
}

@Component({
    selector: 'action-bulk-dialog',
    templateUrl: './action-bulk-dialog.component.html',
    styleUrls: ['./action-bulk-dialog.component.scss']
})
export class ActionBulkDialog implements OnInit {

    scheduleDate: Date;
    deadlineDate: Date;
    clearDeadline?: boolean = false;
    users: User[];
    //usersInGroups: UserOrGroup[];
    //userGroups: UserGroup[];

    selectedStatus?: ActionStatus = null;
    selectedPriority?: Priority = null;
    waitingOnUserOrGroupId: string | number = null;
    selectedOwners: {userIds: string[], groupIds: number[]} = null;
    //selectedUser: User;

    //waitingUserIds: string[] = [];
    //waitingGroupIds: number[] = [];

    get statuses() {
        return statuses;
    }

    get priorities() {
        return priorities;
    }

    protected userIds: string[] = [];
    protected groupIds: number[] = [];

    updated: EventEmitter<{ scheduleDate?: Date, deadlineDate?: Date }>;

    constructor(public dialogRef:MatDialogRef<ActionBulkDialog>,
        @Inject(MAT_DIALOG_DATA) public data: BulkDialogData,
        private route: ActivatedRoute,
        private userService: UserService,
        private hotkeyService: HotkeyService,
    ) { }
    ngOnInit(): void {
        // Register hotkey for "Cancel" action (Ctrl + Q)
        this.hotkeyService.registerHotkey('ctrl+q', 'Cancel action', () => this.onCancel());

        // Register hotkey for "Update" action (Ctrl + Alt + S)
        this.hotkeyService.registerHotkey('ctrl+alt+s', 'Update action', () => {
            if (this.dateValid && this.statusValid) {
              console.log('Update action');
                this.onSubmit();
            }
        });

        // Get users and groups
        this.users = this.userService.users.filter(
            (user) => user.active
        );
        //this.userGroups = this.userService.groups;
    }

    onSubmit() {
        let result: BulkDialogData = {
            scheduleDate: this.scheduleDate,
            deadlineDate: this.deadlineDate,
            clearDeadline: this.clearDeadline,
            actionStatus: this.selectedStatus,
            waitingOnUserOrGroupId: this.waitingOnUserOrGroupId,
            priority: this.selectedPriority,
            owners: this.selectedOwners
        };
        this.dialogRef.close(result);
    }

    onUserOrGroupSelected(event: {userIds: string[], groupIds: number[]}) {
        if (event.userIds?.length > 0) {
            this.waitingOnUserOrGroupId = event.userIds[0];
        } else if (event.groupIds?.length > 0) {
            this.waitingOnUserOrGroupId = event.groupIds[0];
        }
        if (event.userIds?.length == 0 && event.groupIds?.length == 0) {
            this.waitingOnUserOrGroupId = null;
        }
        if (event.userIds?.length > 0 || event.groupIds?.length > 0) {
            this.selectedStatus = ActionStatus.Waiting;
        }
    }

    onStatus(event: any) {
    }
    onStatusChange(event: any) {
        if (event !== ActionStatus.Waiting) {
            this.waitingOnUserOrGroupId = null;
        }
    }

    onPriorityChange(event: any) {
    }

    onOwnerSelected(event: {userIds: string[], groupIds: number[]}) {
        this.selectedOwners = event;
    }

    onClearDate(field: string) {
        this[field] = null;
    }

    get dateValid() : boolean {
        if (this.scheduleDate && this.deadlineDate) {
            return this.scheduleDate <= this.deadlineDate;
        }
        return true;
    }

    get statusValid() : boolean {
        return this.selectedStatus === ActionStatus.Waiting ? this.waitingOnUserOrGroupId !== null : true;
    }


    onTest() {
   }

    onCancel() {
        this.dialogRef.close();
    }
}