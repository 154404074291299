import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthTokenService } from '@intranet/core/services/authtoken.service';

@Injectable({
    providedIn: 'root',
})
export class InfoService {
    constructor(private http: AuthTokenService) {}

    getUsefulInfo(): Observable<any> {
        return this.http.get('/api/info');
    }
}
