import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { HotkeyService } from 'src/app/core/services/hotkey.service';

@Component({
    selector: 'info-modal',
    templateUrl: './info-modal.component.html',
    styleUrls: ['./info-modal.component.scss'],
})
export class InfoModalComponent implements OnInit, OnDestroy {
  constructor(
        public dialogRef: MatDialogRef<InfoModalComponent>,
        private hotkeyService: HotkeyService
    ) {}

    ngOnInit(): void {
        // Add a class to the <body> to disable scrolling
        document.documentElement.classList.add('modal-open');

        // Register the Ctrl + Q hotkey to close the modal
        this.hotkeyService.registerHotkey('ctrl+q', 'Close Info Modal', () =>
          this.onCancel()
        );
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    closeModal(): void {
        this.dialogRef.close();
    }

    ngOnDestroy(): void {
        // Remove the class from the <body> when the modal is closed
        document.documentElement.classList.remove('modal-open');
    }
}
