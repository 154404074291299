import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface Hotkey {
    keys: string; // Example: 'ctrl+q'
    description: string; // Description of the hotkey
    callback: () => void; // Action to execute
}

@Injectable({
    providedIn: 'root',
})
export class HotkeyService {
    private hotkeys: Hotkey[] = [];

    private hotkeyTriggered = new Subject<string>();
    public hotkeyTriggered$ = this.hotkeyTriggered.asObservable();

    // Register a new hotkey
    registerHotkey(keys: string, description: string, callback: () => void): void {
        // Avoid duplicate hotkeys
        this.unregisterHotkey(keys);

        this.hotkeys.push({ keys, description, callback });
    }

    // Unregister a hotkey
    unregisterHotkey(keys: string): void {
        this.hotkeys = this.hotkeys.filter(h => h.keys !== keys);
    }

    // Listen to keyboard events and match hotkeys
    handleKeyPress(event: KeyboardEvent): void {
        const pressedKeys = `${event.ctrlKey ? 'ctrl+' : ''}${event.shiftKey ? 'shift+' : ''}${event.altKey ? 'alt+' : ''}${event.key.toLowerCase()}`;

        const hotkey = this.hotkeys.find(h => h.keys === pressedKeys);
        if (hotkey) {
            event.preventDefault();
            hotkey.callback();
            this.hotkeyTriggered.next(hotkey.keys);
        }
    }

    // Get the list of registered hotkeys
    getHotkeys(): Hotkey[] {
        return this.hotkeys;
    }
}
