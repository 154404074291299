import {
    Component,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { Observable, of, Subject, combineLatest } from 'rxjs';
import { switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import {
    Finance,
    FinanceExtractQuery,
    FinanceSearchQuery,
    FinanceStatus,
    FinanceType,
} from '../finance.types';
import { FinanceService } from '../finance.service';
import { LoginService } from '@intranet/modules/login';
import { MatDialog } from '@angular/material/dialog';
import { HotkeyService } from 'src/app/core/services/hotkey.service';
import { FinanceDeleteDialog } from '../dialogs/delete/finance-delete-dialog.component';
import {
    convertFinanceTypeToName,
    getStatusesByFinanceType,
    navigateToConvert,
} from '../finance.helpers';
import { FinanceApproveDialog } from '../dialogs/approve/finance-approve-dialog.component';
import { FinanceAlertDialog } from '../dialogs/alert/finance-alert-dialog.component';
import { FinanceExtractDialog } from '../dialogs/extract/finance-extract-dialog.component';
import { PageEvent } from '@angular/material/paginator';

@Component({
    selector: 'finance-listing',
    templateUrl: './finance-listing.component.html',
})
export class FinanceListingComponent implements OnInit, OnDestroy {
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    isLoading: boolean = false;
    filtersReady: boolean = false;
    statuses: FinanceStatus[];
    financeType: FinanceType;
    filterQuery: FinanceSearchQuery;
    createUrl: string;
    canCreate: boolean;
    canDelete: boolean;
    totalResults = 0;

    constructor(
        public dialog: MatDialog,
        protected loginService: LoginService,
        private _financeService: FinanceService,
        private _router: Router,
        private _route: ActivatedRoute,
        private hotkeyService: HotkeyService,
    ) {}

    ngOnInit() {
        combineLatest([
            this._financeService.financeStatuses$,
            this._financeService.filterQuery$,
        ])
            .pipe(
                take(1),
                tap(([statuses, filterQuery]) => {
                    this.financeType = this._route.snapshot.data.financeType;
                    this.statuses = getStatusesByFinanceType(
                        statuses,
                        this.financeType
                    );
                    this.filterQuery = filterQuery;
                    this.createUrl = `/finance/${convertFinanceTypeToName(
                        this.financeType,
                        true
                    )}/create`;

                    // Anyone can create a quote, but only finance folks can create an invoice from scratch
                    this.canCreate =
                        this.financeType === 1 ||
                        (this.financeType === 2 &&
                            this.loginService.checkAuth(['finance']));

                    // Finance or Admin role can delete anything - there is also another check in the listing table
                    // where if the finance has not been through an approval, and the logged in user is the creator, then they can also delete
                    this.canDelete = this.loginService.checkAuth([
                        'finance',
                        'admin',
                    ]);
                })
            )
            .subscribe({
                next: () => (this.isLoading = false),
                complete: () => (this.isLoading = false),
            });

        // Register the hotkey for "Add" action
        this.hotkeyService.registerHotkey(
            'ctrl+shift+a',
            'Add new finance item',
            () => this.checkAndAdd()
        );

        this._financeService.filterQuery$
            .pipe(
                takeUntil(this._unsubscribeAll),
                tap((filterQuery) => {
                    this.filterQuery = filterQuery;
                })
            )
            .subscribe();

        this._financeService.financesTotalResults$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe({
                next: (finances) => {
                    this.totalResults = finances;
                },
            });
    }

    ngOnDestroy() {
        this._unsubscribeAll.next('');
        this._unsubscribeAll.complete();
        this._financeService.reset();
    }

    checkAndAdd(): void {
        const currentUrl = this._router.url;

        // Ensure the hotkey only works for finance-related pages
        if (!currentUrl.startsWith('/finance/')) {
            return;
        }

        // Prevent the hotkey from doing anything on /finance/extracts
        if (currentUrl.includes('/finance/extracts')) {
            return;
        }

        // Dynamically set createUrl based on the current page
        if (currentUrl.includes('/finance/estimates')) {
            this.createUrl = '/finance/estimates/create';
        } else if (currentUrl.includes('/finance/invoices')) {
            this.createUrl = '/finance/invoices/create';
        }

        // Navigate to the dynamically set URL if createUrl is valid
        if (this.createUrl) {
            this._router.navigateByUrl(this.createUrl);
        }
    }

    // Call the API via the finance service
    loadFinances(query?: FinanceSearchQuery): Observable<Finance[]> {
        this.isLoading = true;

        return this._financeService.getFinances(query);
    }

    // Called by the main filters (client, date, created by, search)
    onFilter(filterQuery: FinanceSearchQuery) {
        this.isLoading = true;
        this.loadFinances({
            ...filterQuery,
            financeType: this.financeType,
            page: 1,
            isPaginating: false,
        }).subscribe({
            complete: () => (this.isLoading = false),
        });
    }

    onExtract(extractQuery: FinanceExtractQuery) {
        const dialogRef = this.dialog.open(FinanceExtractDialog, {
            width: '320px',
            data: {
                title: extractQuery.title,
                includeCompletedOnly: true,
                excludeExistingExtract: false
            },
        });

        dialogRef
            .afterClosed()
            .pipe(
                switchMap((result) => {
                    if (result) {
                        return this._financeService.createExtract({
                            ...extractQuery,
                            ...result,
                        });
                    }

                    return of(false);
                })
            )
            .subscribe({
                complete: () => {},
                error: (error) => {},
            });
    }

    // Called by switching the status tabs
    onStatus(financeStatusCode: string | 'All'): void {
        if (financeStatusCode === 'All') financeStatusCode = null;
        this.loadFinances({
            ...this.filterQuery,
            financeStatusCode,
            page: 1,
            isPaginating: false,
        }).subscribe({
            complete: () => (this.isLoading = false),
        });
    }

    // Loads more rows for the current query
    onPaginate(e: PageEvent) {
        this.loadFinances({
            ...this.filterQuery,
            page: e.pageIndex + 1,
            isPaginating: true,
        }).subscribe({
            complete: () => {
                this.isLoading = false;
                document.getElementById('page').scrollTo(0, 0);
            }
        });
    }

    onLoading(loading: boolean) {
        this.isLoading = loading;
    }

    onCopy(finance: Finance) {
        this._router.navigateByUrl(
            `/finance/${convertFinanceTypeToName(finance.financeType, true)}/${
                finance.version.id
            }/copy`
        );
    }

    onSort(prop: string) {
        this.isLoading = true;

        const { sortBy: prevSortBy, sortDir: prevSortDir } = this.filterQuery;

        this.loadFinances({
            ...this.filterQuery,
            sortBy: prop,
            sortDir:
                prop === prevSortBy
                    ? prevSortDir === 'asc'
                        ? 'desc'
                        : 'asc'
                    : 'asc',
        }).subscribe({
            complete: () => (this.isLoading = false),
        });
    }

    async onNextStatusStep(finance: Finance) {
        let financeId = finance.financeId;
        let financeType = finance.financeType;
        let versionId = finance.version.id;
        let nextStatus = finance.nextStatus;

        if (nextStatus) {
            if (nextStatus.requiresApproval) {
                if (
                    !this.loginService.checkAuth([
                        'finance',
                        'manager',
                        'admin',
                    ])
                ) {
                    this.showNoPermissonModal();
                } else {
                    this.showApprovalModal(finance, nextStatus);
                }
            } else {
                this._financeService
                    .createVersionStatus(
                        financeId,
                        versionId,
                        nextStatus.financeStatusCode
                    )
                    .pipe(switchMap(() => this.loadFinances(this.filterQuery)))
                    .subscribe({
                        complete: () => (this.isLoading = false),
                        error: (error) => {
                            //this.showError(error);
                            this.isLoading = false;
                        },
                    });
            }
        } else {
            navigateToConvert(versionId, financeType, this._router);
        }
    }

    showNoPermissonModal() {
        this.dialog.open(FinanceAlertDialog, {
            width: '320px',
            data: {
                title: 'Manager approval required',
            },
        });
    }

    showApprovalModal(finance: Finance, nextStatus: FinanceStatus) {
        const dialogRef = this.dialog.open(FinanceApproveDialog, {
            width: '320px',
            data: { finance, nextStatus },
        });

        dialogRef
            .afterClosed()
            .pipe(
                switchMap((result) => {
                    if (result) {
                        return this._financeService.createVersionStatus(
                            finance.financeId,
                            finance.version.id,
                            nextStatus.financeStatusCode,
                            result.action === 'reject'
                        );
                    }

                    return of(false);
                }),
                switchMap(() => this.loadFinances(this.filterQuery))
            )
            .subscribe({
                complete: () => {
                    this.isLoading = false;
                },
                error: (error) => {
                    //this.showError(error);
                    this.isLoading = false;
                },
            });
    }

    onDelete(finance: Finance): void {
        const dialogRef = this.dialog.open(FinanceDeleteDialog, {
            width: '350px',
            data: { finance },
        });

        dialogRef
            .afterClosed()
            .pipe(
                switchMap((result) => {
                    if (result)
                        return this._financeService
                            .delete(result.financeId, result.fullDelete)
                            .pipe(
                                switchMap((result) =>
                                    this.loadFinances({
                                        ...this.filterQuery,
                                    })
                                )
                            );

                    return of(false);
                })
            )
            .subscribe({ complete: () => (this.isLoading = false) });
    }

    onCreate() {
        this._router.navigateByUrl(`/finance/${this.financeType}/create`);
    }
}
