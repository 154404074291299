import {
    Component,
    Input,
    Output,
    OnInit,
    EventEmitter,
    SimpleChanges,
    OnChanges,
    ViewChild
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable, Observer } from 'rxjs';
import { Client, ClientAddress, Contact } from '../client.types';
import { ClientsService } from '../clients.service';
import { HotkeyService } from 'src/app/core/services/hotkey.service';


export class EditContactAction {
    public action: 'add' | 'update' | 'delete' | 'cancel' | 'undelete';
    public contact: Contact | null;
}

@Component({
    selector: 'add-contact',
    templateUrl: './client-add-contact.component.html',
})
export class ClientAddContactComponent implements OnInit, OnChanges {
    @Input() showDialog;
    @Output() update: EventEmitter<any> = new EventEmitter<any>();
    @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild('contactForm') contactForm: NgForm;

    @Input() set contact(val: Contact | null) {
        this._contact = val;
        this.updateLists();

        if (!this.contact || !this.contact.contactId) {
            this.title = 'Add Contact';
        }
        if (this.contact && this.contact.contactId) {
            this.title = 'Edit Contact';
        }
    }

    @Input() set client(val: Client | null) {
        this._client = val;
        this.updateLists();
    }

    get contact(): Contact | null {
        return this._contact;
    }

    get client(): Client | null {
        return this._client;
    }

    selectedAddress: ClientAddress | null;
    isFormOpen: boolean = false;
    isAdd: boolean = true;
    diag: string;
    formObserver: Observer<EditContactAction>;
    _contact: Contact | null;
    title: string;
    _client: Client | null;

    constructor(
        private clientService: ClientsService,
        private router: Router,
        private hotkeyService: HotkeyService,
    ) {}

    ngOnInit(): void {
        // Register hotkey for "Cancel" action (Ctrl + Q)
        this.hotkeyService.registerHotkey('ctrl+q', 'Cancel action', () => this.cancelForm());

        // Register hotkey for "Save Contact" action (Ctrl + Alt + S)
        this.hotkeyService.registerHotkey('ctrl+alt+s', 'Save contact', () => {
          if (this.contactForm?.form.valid && !this.contactForm?.form.pristine) {
              this.onSubmit();
          }
      });

        this.updateLists();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('showDialog' in changes) {
            if (this.showDialog && !this.contact.contactId) {
                this.selectedAddress = null;
            }
        }
    }

    updateLists(): void {
        if (this.contact && this.contact.clientAddressId) {
            //console.log("init", this.contact);
            this.selectedAddress = this.client.addresses.find(
                (a) => a.clientAddressId === this.contact.clientAddressId
            );
        }
    }

    cancelForm() {
        //this.isFormOpen = false;
        this.contact = null;

        // Retrieves showDialog input, turns to false and returns back to parent in order to close modal
        this.showDialog = false;
        this.close.emit(this.showDialog);

        // TODO - Check Add Contact returns when Cancel is pressed
        let action = { action: 'cancel', contact: null } as EditContactAction;
        this.formObserver.next(action);
        this.formObserver.complete();
    }

    onSubmit() {
        if (!this.client) {
            throw new Error('NULL client in add contact');
        }
        if (!this.contact) {
            throw new Error('NULL contact in add contact');
        }
        this.clientService
            .addClientContact(this.client, this.contact)
            .subscribe(
                (ctct) => {
                    this.contact = null;
                    var typeOfAction = this.isAdd ? 'add' : 'update';
                    var action = {
                        action: typeOfAction,
                        contact: ctct,
                    } as EditContactAction;
                    this.showDialog = false;
                    this.formObserver.next(action);
                    this.formObserver.complete();

                    this.update.emit();
                    this.close.emit(this.showDialog);
                },
                (error) => alert(error)
            );
    }

    addOrEdit(client: Client, contact: Contact): Observable<EditContactAction> {
        if (contact.contactId) {
            this.isAdd = false;
        } else {
            this.isAdd = true;
        }

        this.client = client;
        this.contact = contact;
        return Observable.create((obs: Observer<EditContactAction>) => {
            this.formObserver = obs;
        });
    }

    selectedAddressChanged() {
        if (this.selectedAddress) {
            this.contact.clientAddressId = this.selectedAddress.clientAddressId;
        }
    }
}
