import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AbstractControl, FormArray, FormBuilder, FormGroup, ValidationErrors } from "@angular/forms";
import { JiraLinksForJob, Job, JobService } from "@intranet/modules/jobs";
import { HotkeyService } from 'src/app/core/services/hotkey.service';

@Component({
    selector: 'job-jira-links',
    templateUrl: './job-jira-links.component.html',
    styleUrls: ['./job-jira-links.component.scss'],
})
export class JobJiraLinksComponent implements OnInit {
    @Input() job: Job;
    @Output() changed = new EventEmitter();
    @Output() cancelled = new EventEmitter();
    jiraLinks: JiraLinksForJob[];
    form: FormGroup;

    constructor(private jobService: JobService,
                private formBuilder: FormBuilder,
                private hotkeyService: HotkeyService
    ) { }

    ngOnInit(): void {
        // Register hotkey for "Cancel" action (Ctrl + Q)
        this.hotkeyService.registerHotkey('ctrl+q', 'Cancel action', () => this.cancel());

        // Register hotkey for "Save" action (Ctrl + Alt + S)
        this.hotkeyService.registerHotkey('ctrl+alt+s', 'Save action', () => {
            if (this.form?.valid) {
                this.save();
            }
        });

        this.getJiraLinksForJob();
    }

    getJiraLinksForJob() {
        this.jobService.getJiraLinksForJob(this.job.jobId).subscribe((jiraLinks) => {
            if (jiraLinks.success) {
                this.form = this.formBuilder.group({
                    jiraLinks: this.formBuilder.array([])
                });
                jiraLinks.links.forEach(link => {
                    (this.form.get('jiraLinks') as FormArray).push(this.formBuilder.group({
                        url: [link.url],
                        title: [link.title],
                        jobId: [link.jobId],
                        jiraLinkId: [link.jiraLinkId]
                    }
                    ,{ validators: this.validateUrl}
                ));
                });
                if (jiraLinks.links.length === 0) {
                    this.addLink();
                }
            }
        });
    }

    validateUrl(group: AbstractControl) : ValidationErrors | null {
        const url = group.get('url').value;
        if (!url) {
            return null;
        }
        if (!url.startsWith('http://') && !url.startsWith('https://')) {
            return { 'url': 'That is not a valid Jira url' };
        }
        return null;
    }

    getLinks() {
        return this.form.get('jiraLinks') as FormArray;
    }

    leaveUrl(index: number) {
        const link = this.getLinks().at(index);
        const url = link.get('url').value;
        const title = link.get('title').value;
        if (!url || title) {
            return;
        }
        
        this.jobService.getJiraName(url).subscribe((result) => {
            if (result.success) {
                link.get('title').setValue(result.jiraName);
            } else {
                link.get('title').setValue('Unable to find Jira name');
            }
        });
    }

    addLink() {
        this.getLinks().push(this.formBuilder.group({
            url: [''],
            title: [''],
            jobId: [this.job.jobId],
            jiraLinkId: [0]
        },{ validators: this.validateUrl}));
    }

    removeLink(index: number) {
        this.getLinks().removeAt(index);
    }

    cancel() {
        this.cancelled.emit();
    }

    save() {
        let links = this.getLinks().value;
        let payload = {
            jobId: this.job.jobId,
            links: links
        }
        this.jobService.updateJiraLinksForJob(payload).subscribe((result) => {
            this.changed.emit();
        });
    }
}