import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Currency } from '../../finance.types';
import { take, tap } from 'rxjs/operators';
import { FinanceService } from '../../finance.service';
import { HotkeyService } from 'src/app/core/services/hotkey.service';

export interface DialogData {
    currencyId: number;
    includeCompletedOnly: boolean;
    excludeExistingExtract: boolean;
    title: string;
}

@Component({
    selector: 'finance-extract-dialog',
    templateUrl: 'finance-extract-dialog.component.html',
    styleUrls: ['finance-extract-dialog.component.scss'],
})
export class FinanceExtractDialog {
    currencies: Currency[];

    constructor(
        public dialogRef: MatDialogRef<FinanceExtractDialog>,
        private _financeService: FinanceService,
        private hotkeyService: HotkeyService,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    ngOnInit() {
        // Register hotkey for "Cancel" action (Ctrl + Q)
        this.hotkeyService.registerHotkey('ctrl+q', 'Cancel action', () => this.onCancel());

        this._financeService.currencies$
            .pipe(
                take(1),
                tap((currencies) => {
                    this.currencies = currencies;
                    this.data.currencyId = this.currencies.find(
                        (c) => c.code === 'GBP'
                    )?.currencyId;
                })
            )
            .subscribe();
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}
