import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HotkeyService } from 'src/app/core/services/hotkey.service';
import { ModalService } from '@intranet/shared/modal/info-modal.service';

@Component({
    selector: 'app-info-button',
    templateUrl: './info-button.component.html', // Use templateUrl here
    styleUrls: ['./info-button.component.scss'],
})
export class InfoButtonComponent {

    constructor(
        private dialog: MatDialog,
        private hotkeyService: HotkeyService,
        private modalService: ModalService
    ) {}

    ngOnInit(): void {
        // Register the Ctrl + I hotkey for opening the modal
        this.hotkeyService.registerHotkey('ctrl+i', 'Open Info Modal', () => this.openInfoModal());
    }

    openInfoModal(): void {
        this.modalService.openInfoModal();
    }
}
